import { AxiosResponse } from 'axios';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import { observable, computed, action, IObservableArray, when } from 'mobx';
import uuid from 'uuid/v4';

import {
  CreatePractionerRoleIT,
  ExtendedRole,
  archivePractitionerRole,
  archivePractitionerRoleV2,
  createPractitionerRole,
  createPractitionerRoleV2,
  editPractitionerRole,
  editPractitionerRoleV2,
} from 'api/practitionerApi';
import { ROLES_KEYS, Config } from 'constants/practitioner';
import { ROLES } from 'constants/roles';
import RootStore from 'stores/RootStore';
import { sortWithLocale } from 'utils/textUtils';

import { PractitionerRoleDefinition } from '../PractitionerRoles/PractitionerRoles';

export interface RoleInCareUnit {
  role: ROLES;
  isPrimaryRole: boolean;
  lockedFromAutoManagement?: boolean;
  careUnitId: string;
  careUnitName: string;
  rowType: ClinicRoleRowType;
  roleId: string;
  'data-testid'?: string;
}

interface CustomError extends AxiosResponse {
  response: {
    status: number;
    data: {
      type: string;
    };
  };
}

export enum ClinicRoleRowType {
  careUnit = 'careUnit',
  rolesGroup = 'rolesGroup',
  role = 'role',
}

export interface StructuredRoleInCareUnit {
  id: string;
  label: string;
  rowType: ClinicRoleRowType;
  'data-testid'?: string;
  children: Array<StructuredRoleInCareUnit | RoleInCareUnit>;
}

class PractitionerRolesStore {
  @observable allRoles: IObservableArray<RoleInCareUnit> = observable.array([]);

  constructor(private rootStore: RootStore) {}

  @computed
  get patientReceivingRoles() {
    return this.allRoles.filter(role => this.filterRoles(role.role, ROLES_KEYS.RESOURCE_TYPES));
  }

  @computed
  get administrativeRoles() {
    return this.allRoles.filter(role =>
      this.filterRoles(role.role, ROLES_KEYS.ADMINISTRATIVE_ROLES)
    );
  }

  /**
   * function generates a map of extended roles for a practitioner with just care unit id
   * because one can not have more than one practitioner role in the same care unit
   * ie. if one has Physician role in care unit doctor24, one can not have another role say Nurse in the same care unit.
   */
  @computed
  get extendedPractitionerRolesMap() {
    const { practitionerStore, rolesStore } = this.rootStore;

    if (!practitionerStore.data.extendedRoles) {
      return {};
    }
    return practitionerStore.data.extendedRoles.reduce(
      (acc: Record<string, boolean>, role: ExtendedRole) => {
        if (!rolesStore.administrativeRoles.includes(role.role)) {
          acc[role.careUnit.id] = true;
        }
        return acc;
      },
      {}
    );
  }

  /**
   * function generates a map of extended roles for an adminstrative role with just care unit id and role
   * because one can have more than one role different roles in the same care unit.
   * ie. one can have both scheduler and admin role in the same care unit say doctor24.
   * NB. One can not have scheduler and scheduler in the same care unit say doctor24.
   */
  @computed
  get extendedAdministrativeRolesMap() {
    const { practitionerStore, rolesStore } = this.rootStore;
    if (!practitionerStore.data.extendedRoles) {
      return {};
    }
    return practitionerStore.data.extendedRoles.reduce(
      (acc: Record<string, boolean>, role: ExtendedRole) => {
        if (rolesStore.administrativeRoles.includes(role.role)) {
          acc[`${role.role}:${role.careUnit.id}`] = true;
        }
        return acc;
      },
      {}
    );
  }

  @computed
  get groupedByCareUnit(): StructuredRoleInCareUnit[] {
    const groupedByCareUnitId = groupBy(this.allRoles, 'careUnitId');

    return Object.entries(groupedByCareUnitId).map(([careUnitId, data]) => {
      const patientReceivingRoles = data
        .filter(role => this.filterRoles(role.role, ROLES_KEYS.RESOURCE_TYPES))
        .map(role => ({ ...role, 'data-testid': role.roleId }));
      const administrativeRoles = data
        .filter(role => this.filterRoles(role.role, ROLES_KEYS.ADMINISTRATIVE_ROLES))
        .map(role => ({ ...role, 'data-testid': role.roleId }));
      return {
        id: careUnitId,
        'data-testid': careUnitId,
        label: data[0].careUnitName,
        rowType: ClinicRoleRowType.careUnit,
        children: [
          ...(patientReceivingRoles.length
            ? [
                {
                  id: uuid(),
                  label: 'practitioner-roles-form.practitioner-roles-header',
                  rowType: ClinicRoleRowType.rolesGroup,
                  children: patientReceivingRoles,
                },
              ]
            : []),
          ...(administrativeRoles.length
            ? [
                {
                  id: uuid(),
                  label: 'practitioner-roles-form.administrative-roles-header',
                  rowType: ClinicRoleRowType.rolesGroup,
                  children: administrativeRoles,
                },
              ]
            : []),
        ],
      };
    });
  }

  initializeRoles = (practitionerId: string) => {
    const { practitionerStore } = this.rootStore;
    when(
      () => !!practitionerStore.data.id && practitionerStore.data.id === practitionerId,
      () => {
        this.allRoles = practitionerStore.data.extendedRoles
          .map(this.mapRoleInCareUnit)
          .sort((a: RoleInCareUnit, b: RoleInCareUnit) => sortWithLocale(a, b, 'careUnitName'));
      }
    );
  };

  @action
  disposeRoles = () => {
    this.allRoles.replace([]);
  };

  // NB. no need to for primary role logic on care unit as backend will handle the implementation.
  updatePractitionerRole = async (practitionerId: string, extendedRole: RoleInCareUnit) => {
    const { practitionerStore } = this.rootStore;
    const { careUnitId, role, isPrimaryRole, lockedFromAutoManagement, roleId } = extendedRole;
    const payload = {
      careUnitId,
      role,
      isPrimaryRole,
      lockedFromAutoManagement,
    };

    try {
      practitionerStore.isLoading();
      await editPractitionerRole(practitionerId, roleId, payload);
      await practitionerStore.refetchPractitioner(practitionerId);
      this.rootStore.flashMessageService.translatedSuccess('practitioner.roles-saved-message');
    } catch (e) {
      practitionerStore.manageException(e);
    } finally {
      this.initializeRoles(practitionerId);
      practitionerStore.isLoaded();
    }
  };

  updatePractitionerRoleV2 = async (
    practitionerId: string,
    extendedRole: RoleInCareUnit,
    partnerId?: string
  ) => {
    const { practitionerStore } = this.rootStore;
    const { careUnitId, role, isPrimaryRole, lockedFromAutoManagement, roleId } = extendedRole;
    const payload = {
      careUnitId,
      role,
      isPrimaryRole,
      lockedFromAutoManagement,
    };

    try {
      practitionerStore.isLoading();
      await editPractitionerRoleV2(practitionerId, roleId, payload, careUnitId);
      await practitionerStore.refetchPractitioner(practitionerId);
      this.rootStore.flashMessageService.translatedSuccess('practitioner.roles-saved-message');
    } catch (e) {
      this.rootStore.flashMessageService.translatedError(
        'practitioner.errors.principal-has-access-by-switching-partner',
        undefined,
        { id: partnerId }
      );
    } finally {
      this.initializeRoles(practitionerId);
      practitionerStore.isLoaded();
    }
  };

  archivePractitionerExtendedRole = async (practitionerId: string, roleId: string) => {
    const { practitionerStore } = this.rootStore;
    try {
      practitionerStore.isLoading();
      await archivePractitionerRole(practitionerId, roleId);
      await practitionerStore.refetchPractitioner(practitionerId);
      this.rootStore.flashMessageService.translatedSuccess('practitioner.roles-saved-message');
    } catch (error) {
      practitionerStore.manageException(error);
    } finally {
      this.initializeRoles(practitionerId);
      practitionerStore.isLoaded();
    }
  };

  archivePractitionerExtendedRoleV2 = async (
    practitionerId: string,
    roleId: string,
    careUnitId: string,
    partnerId?: string
  ) => {
    const { practitionerStore } = this.rootStore;
    try {
      practitionerStore.isLoading();
      await archivePractitionerRoleV2(practitionerId, roleId, careUnitId);
      await practitionerStore.refetchPractitioner(practitionerId);
      this.rootStore.flashMessageService.translatedSuccess('practitioner.roles-saved-message');
    } catch (e) {
      const error = e as unknown as CustomError;
      if (error.response.status === 400 && error.response.data.type === '1006') {
        // user has access to delete care unit by switching to the care unit in question.
        this.rootStore.flashMessageService.translatedError(
          'practitioner.errors.principal-has-access-by-switching-partner',
          undefined,
          { id: partnerId }
        );
      } else if (error.response.status === 403 && error.response.data.type === '1007') {
        // user does not have access to delete role in the care unit in question
        this.rootStore.flashMessageService.translatedError(
          'practitioner.errors.principal-does-not-have-access'
        );
      } else {
        this.rootStore.flashMessageService.translatedError('general.error');
      }
    } finally {
      this.initializeRoles(practitionerId);
      practitionerStore.isLoaded();
    }
  };

  displayDeletedCareUnitErrorMessage = (careUnitIds: string[]) => {
    // 1. get deleted care unit name and id from extendedRole/allRoles
    // 2. show the error message displaying the names of care units and id as per previous implementation.

    // will save id of care unit whose error message has been displayed to prevent duplicate messages for same care unit id.
    const deletedCareUnitIds = new Set();

    for (const role of this.allRoles) {
      if (careUnitIds.includes(role.careUnitId) && !deletedCareUnitIds.has(role.careUnitId)) {
        deletedCareUnitIds.add(role.careUnitId);
        this.rootStore.flashMessageService.translatedError(
          'practitioner.errors.no-care-unit-found',
          undefined,
          { id: role.careUnitId, name: role.careUnitName }
        );
      }
    }
  };

  addNewRole = async (practitionerId: string, practitionerRole: PractitionerRoleDefinition) => {
    const { partnersStore, practitionerStore, careUnitsStore } = this.rootStore;
    const { careUnitIds, role, lockedFromAutoManagement } = practitionerRole;
    if (!role) {
      return;
    }
    const roleLock = partnersStore.partnerCustomizations.get(
      Config.ADMIN_CAN_LOCK_ROLES_FROM_AUTO_MANAGEMENT
    )
      ? { lockedFromAutoManagement }
      : {};
    const payload: CreatePractionerRoleIT = {
      careUnitIds,
      role,
      isPrimaryRole: false,
      ...roleLock,
    };

    // handle deleted care units
    // care unit is deleted if care unit id is not found in the list of care units.
    const areSomeCareUnitsDeleted =
      careUnitsStore.allCareUnits.filter(careUnit => careUnitIds.includes(careUnit.id)).length <
      careUnitIds.length;

    if (areSomeCareUnitsDeleted) {
      this.displayDeletedCareUnitErrorMessage(careUnitIds);
      return;
    }

    try {
      await createPractitionerRole(practitionerId, payload);
      await practitionerStore.refetchPractitioner(practitionerId);
      this.rootStore.flashMessageService.translatedSuccess('practitioner.roles-saved-message');
    } catch (error) {
      practitionerStore.manageException(error);
    } finally {
      this.initializeRoles(practitionerId);
      practitionerStore.isLoaded();
    }
  };

  addNewRoleV2 = async (practitionerId: string, practitionerRole: PractitionerRoleDefinition) => {
    const { partnersStore, practitionerStore } = this.rootStore;
    const { careUnitIds, role, lockedFromAutoManagement } = practitionerRole;
    if (!role) {
      return;
    }
    const roleLock = partnersStore.partnerCustomizations.get(
      Config.ADMIN_CAN_LOCK_ROLES_FROM_AUTO_MANAGEMENT
    )
      ? { lockedFromAutoManagement }
      : {};
    const payload: CreatePractionerRoleIT = {
      careUnitIds,
      role,
      isPrimaryRole: false,
      ...roleLock,
    };

    try {
      await createPractitionerRoleV2(practitionerId, payload);
      await practitionerStore.refetchPractitioner(practitionerId);
      this.rootStore.flashMessageService.translatedSuccess('practitioner.roles-saved-message');
    } catch (error) {
      practitionerStore.manageException(error);
    } finally {
      this.initializeRoles(practitionerId);
      practitionerStore.isLoaded();
    }
  };

  getFilteredRoles = (type: ROLES_KEYS) => {
    switch (type) {
      case ROLES_KEYS.ADMINISTRATIVE_ROLES:
        return this.administrativeRoles;
      case ROLES_KEYS.RESOURCE_TYPES:
        return this.patientReceivingRoles;
      default:
        return [];
    }
  };

  mapRoleInCareUnit = (extendedRole: ExtendedRole): RoleInCareUnit => ({
    role: extendedRole.role,
    rowType: ClinicRoleRowType.role,
    isPrimaryRole: extendedRole.isPrimaryRole,
    lockedFromAutoManagement: extendedRole.lockedFromAutoManagement,
    careUnitId: get(extendedRole, 'careUnit.id', ''),
    careUnitName: get(extendedRole, 'careUnit.name', ''),
    roleId: extendedRole.id,
  });

  filterRoles = (role: ROLES, type: ROLES_KEYS) => this.rootStore.rolesStore[type].includes(role);
}

export default PractitionerRolesStore;
