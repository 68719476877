import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Typography, Input, Table, Button, Popconfirm } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { computed, toJS } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component, ContextType } from 'react';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';

import DraggableRow from 'components/DraggableRow';
import PageHeader from 'components/PageHeader';
import PlusFloatingButton from 'components/PlusFloatingButton';
import RootStoreContext from 'context/RootStoreContext';
import { Rule, RuleDraft, SEND_RULES_DATA_MODE } from 'modules/Rules/types';
import { capitalizeFirst } from 'utils/textUtils';

import { RuleForm } from '../RuleForm';
import { RulesPackageMetaForm } from '../RulesPackageForm';
import styles from '../shared/SharedStyles.module.css';

interface RulesPackageProps extends RouteComponentProps<{ id: string }>, WrappedComponentProps {}

const dndProps = {
  backend: HTML5Backend,
};

@observer
class RulesPackageComponent extends Component<RulesPackageProps> {
  static contextType = RootStoreContext;
  declare context: ContextType<typeof RootStoreContext>;

  state = {
    isFormVisible: false,
    activeRule: null,
  };

  componentDidMount() {
    const { rulesPackageStore } = this.context;
    const {
      match: {
        params: { id },
      },
    } = this.props;

    rulesPackageStore.initialize(id);
  }

  componentWillUnmount(): void {
    this.context.rulesPackageStore.clearRulesPackage();
  }

  components = {
    body: {
      row: DraggableRow,
    },
  };

  private get breadcrumbs() {
    const {
      rulesPackageStore: { rulesPackage },
    } = this.context;
    return [
      {
        iconName: 'pic-left',
        text: <FormattedMessage id="main-navigation.rules" />,
      },
      {
        text: (
          <Link to={'/rules/'}>
            {capitalizeFirst(this.props.intl.formatMessage({ id: 'rules.packages' }))}
          </Link>
        ),
      },
      {
        text: rulesPackage?.id ?? '...',
      },
    ];
  }

  @computed
  get columns() {
    const baseColumns: ColumnsType<Rule> = [
      {
        title: <FormattedMessage id="rules.id" />,
        dataIndex: 'id',
      },
      {
        title: <FormattedMessage id="general.description" />,
        dataIndex: 'description',
      },
      {
        title: <FormattedMessage id="rules.condition" />,
        dataIndex: 'when',
      },
      {
        title: <FormattedMessage id="rules.outcome" />,
        dataIndex: 'outcome',
      },
      {
        title: <FormattedMessage id="general.actions" />,
        width: 100,
        render: (_: string, rule: Rule) => this.renderActionButtons(rule),
      },
    ];

    return baseColumns;
  }

  renderActionButtons = (rule: Rule) => {
    const {
      rulesPackageStore: { canSaveRule, areRulesEditable },
    } = this.context;

    return (
      <>
        {!canSaveRule && (
          <Button
            type="link"
            icon={<EyeOutlined />}
            onClick={() => this.showRuleFormHandler(rule)}
          />
        )}
        {canSaveRule && (
          <>
            {areRulesEditable && (
              <Button
                type="link"
                icon={<EditOutlined />}
                onClick={() => this.showRuleFormHandler(rule)}
              />
            )}
            <Popconfirm
              title={<FormattedMessage id="general.sure-to-delete" />}
              cancelText={<FormattedMessage id="general.cancel" />}
              onConfirm={() => this.onDeleteClickHandler(rule)}
            >
              <Button type="link" icon={<DeleteOutlined />} />
            </Popconfirm>
          </>
        )}
      </>
    );
  };

  showRuleFormHandler = (rule: Rule | null) => {
    this.setState({ isFormVisible: true, activeRule: toJS(rule) });
  };

  hideRuleFormHandler = async () => {
    this.setState({ isFormVisible: false, activeRule: null });
  };

  onDeleteClickHandler = async (ruleData: Rule) => {
    const {
      rulesPackageStore: { sendRuleData },
    } = this.context;
    await sendRuleData(ruleData, { mode: SEND_RULES_DATA_MODE.DELETE });
  };

  saveRuleHandler = async (ruleData: Rule | RuleDraft) => {
    const {
      rulesPackageStore: { sendRuleData, getRuleById },
    } = this.context;

    const mode = getRuleById(ruleData.id)
      ? SEND_RULES_DATA_MODE.UPDATE
      : SEND_RULES_DATA_MODE.CREATE;

    await sendRuleData(ruleData, { mode });
    this.hideRuleFormHandler();
  };

  ruleMoveHandler = async (fromIndex: number, toIndex: number) => {
    const {
      rulesPackageStore: { changeRuleIndex, rules },
    } = this.context;

    const movedRule = toJS(rules)[fromIndex];
    return changeRuleIndex(fromIndex, toIndex, movedRule.id);
  };

  render() {
    const {
      rulesPackageStore: {
        searchTerm,
        canSaveRule,
        isLoading,
        onSearchChangeHandler,
        rules,
        rulesPackage,
        updateRulesPackageMeta,
        rulesPackageMeta,
      },
    } = this.context;

    return (
      <>
        <PageHeader
          content={
            <div className={styles.headerTop}>
              <Typography.Title level={2} className={styles.title}>
                <span>{rulesPackage?.id ?? '...'}</span>
              </Typography.Title>
            </div>
          }
          breadcrumbs={this.breadcrumbs}
          headerActions={
            <div className={styles.headerActions}>
              <Input.Search
                placeholder={this.props.intl.formatMessage({ id: 'general.search' })}
                onChange={onSearchChangeHandler}
                className={styles.headerAction}
                disabled={isLoading}
              />
            </div>
          }
        />
        <RulesPackageMetaForm
          data={rulesPackageMeta}
          onSubmit={updateRulesPackageMeta}
          isDisabled={!canSaveRule}
          isLoading={isLoading}
        />
        <Typography.Title level={3}>
          {this.props.intl.formatMessage({ id: 'rules' })}
        </Typography.Title>
        <DndProvider {...dndProps}>
          <Table<Rule>
            columns={this.columns}
            loading={isLoading}
            dataSource={rules.slice()}
            className={styles.table}
            pagination={false}
            components={this.components}
            onRow={(_, index) => ({
              index,
              moveRow: this.ruleMoveHandler,
              // prevent dragging rules if list is filtered
              // dragging list item === send request to change `index` prop of item
              // hard to predict new `index` value if list is a result of filtering
              isDraggable: canSaveRule && !searchTerm,
              // Required by onRow return type
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              onClick: () => {},
            })}
            rowKey="id"
          />
        </DndProvider>
        <RuleForm
          rule={this.state.activeRule}
          visible={this.state.isFormVisible}
          isSaving={isLoading}
          onSubmit={this.saveRuleHandler}
          onCancel={this.hideRuleFormHandler}
          isFormDisabled={!canSaveRule}
        />
        {canSaveRule && (
          <PlusFloatingButton
            onClick={() => this.showRuleFormHandler(null)}
            testId="rules-add-rule"
          />
        )}
      </>
    );
  }
}

export const RulesPackage = injectIntl(withRouter(RulesPackageComponent));
