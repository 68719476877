import { LoadingOutlined, PicLeftOutlined } from '@ant-design/icons';
import { Spin, Tabs } from 'antd';
import { Formik, FormikValues } from 'formik';
import { Form } from 'formik-antd';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component, ContextType, Fragment } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';

import { AppBreadcrumbItem } from 'components/Breadcrumbs';
import withOnUnloadGuard, { OnUnloadGuardProps } from 'components/HOC/withOnUnloadGuard';
import PageHeader from 'components/PageHeader';
import RichTextEditor from 'components/RichTextEditor';
import { LANGS } from 'constants/enums';
import { RICH_TEXT_CONTENT_TYPES } from 'constants/text';
import RootStoreContext from 'context/RootStoreContext';
import StatusTag from 'modules/Content24/components/StatusTag';

import styles from './EditSelfcare.module.css';
import { EditSelfCareControls } from './EditSelfCareControls';
import { SELF_CARE_STATUS } from '../api/selfcareApi';
import { RenderPreview } from '../components/RenderPreview/RenderPreview';
import { wrapWithHtmlDocumentMeta } from '../utils/html';

/**
 * @notExported
 */
interface EditSelfcareProps
  extends WrappedComponentProps,
    RouteComponentProps<{ id: string }>,
    OnUnloadGuardProps {}

@observer
class EditSelfcare extends Component<EditSelfcareProps> {
  static contextType = RootStoreContext;
  declare context: ContextType<typeof RootStoreContext>;

  @computed
  get breadcrumbs(): AppBreadcrumbItem[] {
    return [
      {
        icon: <PicLeftOutlined />,
        text: <FormattedMessage id="main-navigation.content24" />,
      },
      {
        text: <FormattedMessage id="selfcare.list.header" />,
        link: '/content24/selfcare',
      },
      {
        text: this.context.selfcareStore.current?.id,
      },
    ];
  }

  async componentDidMount() {
    const {
      match: {
        params: { id },
      },
      history,
    } = this.props;
    const { selfcareStore } = this.context;

    await selfcareStore.fetchSelfcareAdvice(id);

    if (!selfcareStore.current) {
      history.replace('/content24/selfcare');
    }
  }

  componentWillUnmount() {
    this.context.selfcareStore.clearCurrentSelfCareAdvice();
  }

  handleInputChange = () => {
    this.props.updateIsDirty(true);
  };

  handleSubmit = async (values: FormikValues) => {
    const content =
      this.context.selfcareStore.selfCareLocalizedFile.type === RICH_TEXT_CONTENT_TYPES.HTML
        ? wrapWithHtmlDocumentMeta(values.text)
        : values.text;

    await this.context.selfcareStore.handleSelfcareAdviceUpdate(content);

    this.setIsDraft(false);
  };

  private setIsDraft = (isDraft: boolean) => {
    this.props.updateIsDirty(isDraft);
  };

  handleSelfcareAdviceDelete = async () => {
    const { selfcareStore } = this.context;
    await selfcareStore.handleSelfcareAdviceDelete();

    if (!selfcareStore.current) {
      this.props.history.replace('/content24/selfcare');
    }
  };

  getContentStatusTag = (lang: LANGS) => {
    const { selfcareStore } = this.context;
    const current = selfcareStore.current;

    if (!current) {
      return null;
    }

    const isLocalCopy =
      current.languageToStatus[lang] === SELF_CARE_STATUS.LOCAL &&
      current.status === SELF_CARE_STATUS.LOCAL;
    const isModified =
      current.languageToStatus[lang] === SELF_CARE_STATUS.LOCAL &&
      current.status !== SELF_CARE_STATUS.LOCAL;

    if (isLocalCopy) {
      return 'localCopy';
    }

    if (isModified) {
      return 'modified';
    }

    return null;
  };

  render() {
    const { selfcareStore, content24Store } = this.context;
    const current = selfcareStore.current;
    const isLoading = selfcareStore.isLoading;
    const isSaving = selfcareStore.isSaving;
    const showPreview = selfcareStore.isPreviewMode;
    const isEditModeEnabled = content24Store.canEditSelfcareAdvice && selfcareStore.lang;
    const isDeleteContentEnabled =
      isEditModeEnabled && current?.languageToStatus[selfcareStore.lang] === SELF_CARE_STATUS.LOCAL;

    return (
      <Fragment>
        <PageHeader content="selfcare.edit.header" breadcrumbs={this.breadcrumbs} />
        {!selfcareStore.lang && <Spin size="large" indicator={<LoadingOutlined spin />} />}
        {!!selfcareStore.lang && (
          <Tabs
            defaultActiveKey={selfcareStore.lang}
            onChange={key => selfcareStore.handleLangTabChange(key as LANGS)}
            className={styles.tabs}
            tabBarExtraContent={
              // These controls have to be included twice, here and in the Formik form. That's because they:
              // a) depend on form state when editing the content (see Formik Form below),
              // b) are form independent when previewing content (see here).
              // In each of these cases they have a bit different config.
              isEditModeEnabled && showPreview ? (
                <EditSelfCareControls
                  onDelete={isDeleteContentEnabled ? this.handleSelfcareAdviceDelete : undefined}
                />
              ) : undefined
            }
            items={content24Store.availableLanguages.map(lang => {
              const contentStatus = this.getContentStatusTag(lang);
              return {
                key: lang,
                disabled: isLoading || isSaving,
                label: (
                  <div className={styles.langTab}>
                    <FormattedMessage id={`general.language-${lang}`} />
                    {contentStatus && (
                      <StatusTag status={contentStatus} className={styles.statusTag} />
                    )}
                  </div>
                ),
                children: (
                  <>
                    {isLoading && <Spin size="large" indicator={<LoadingOutlined spin />} />}
                    {!isLoading && showPreview && (
                      <RenderPreview
                        type={selfcareStore.selfCareLocalizedFile.type}
                        text={selfcareStore.selfCareLocalizedFile.text}
                      />
                    )}
                    {!isLoading && !showPreview && (
                      <Formik
                        enableReinitialize
                        initialValues={selfcareStore.selfCareLocalizedFile}
                        onSubmit={this.handleSubmit}
                      >
                        {({ isValid, setFieldValue, values, dirty }) => (
                          <Form layout="vertical">
                            <EditSelfCareControls
                              onDelete={
                                isDeleteContentEnabled ? this.handleSelfcareAdviceDelete : undefined
                              }
                              isValid={isValid && dirty}
                            />
                            <Form.Item name="text">
                              <RichTextEditor
                                isDisabled={isSaving}
                                initialValue={values.text}
                                onChange={content => {
                                  this.setIsDraft(true);
                                  setFieldValue('text', content);
                                }}
                                contentType={selfcareStore.selfCareLocalizedFile.type}
                                paragraphControls
                                imageControls
                              />
                            </Form.Item>
                          </Form>
                        )}
                      </Formik>
                    )}
                  </>
                ),
              };
            })}
          />
        )}
      </Fragment>
    );
  }
}

export default withOnUnloadGuard(injectIntl(EditSelfcare));
