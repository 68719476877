import { createStore } from 'jotai';
import { configure } from 'mobx';

import ConditionVisualizationStore from 'modules/Content24/ConditionVisualization/stores/ConditionVisualizationStore';
import ConditionsListStore from 'modules/Content24/stores/ConditionsListStore';
import ConditionStore from 'modules/Content24/stores/ConditionStore';
import Content24Store from 'modules/Content24/stores/Content24Store';
import SelfcareStore from 'modules/Content24/stores/SelfcareStore';
import OriginsListStore from 'modules/Origins/OriginsList/stores/OriginsListStore';
import UserOriginPermissionListStore from 'modules/Origins/OriginsList/stores/UserOriginPermissionListStore';
import MergeRequestStore from 'modules/PartnerStatus/stores/MergeRequestStore';
import PartnerStatusStore from 'modules/PartnerStatus/stores/PartnerStatusStore';
import PractitionerRolesStore from 'modules/Practitioner/stores/PractitionerRolesStore';
import RulesPackagesListStore from 'modules/Rules/stores/RulesPackagesListStore';
import RulesPackageStore from 'modules/Rules/stores/RulesPackageStore';
import UserListStore from 'modules/User/stores/UserListStore';
import { UserStore } from 'modules/User/stores/UserStore';
import { FlashMessageService, FlashMessageOptions } from 'types/types';

import AuthStore from './AuthStore';
import CareUnitsStore from './CareUnitsStore';
import CareUnitStore from './CareUnitStore';
import CustomizationsStore from './CustomizationsStore';
import OriginCareUnitsStore from './OriginCareUnitsStore';
import OriginStore from './OriginStore';
import PartnersStore from './PartnersStore';
import PractitionerFindStore from './PractitionerFindStore';
import PractitionersStore from './PractitionersStore';
import PractitionerStore from './PractitionerStore';
import RolesStore from './RolesStore';
import UserPermissionsStore from './UserPermissionsStore';
import UserDataStore from './UserStore';

configure({ enforceActions: 'observed' });

export default class RootStore {
  flashMessageService: FlashMessageService<string, FlashMessageOptions>;

  userDataStore: UserDataStore;
  userStore: UserStore;
  userListStore: UserListStore;
  practitionersStore: PractitionersStore;
  practitionerFindStore: PractitionerFindStore;
  careUnitsStore: CareUnitsStore;
  careUnitStore: CareUnitStore;
  practitionerStore: PractitionerStore;
  originStore: OriginStore;
  originCareUnitsStore: OriginCareUnitsStore;
  customizationsStore: CustomizationsStore;
  rolesStore: RolesStore;
  practitionerRolesStore: PractitionerRolesStore;
  authStore: AuthStore;
  originsListStore: OriginsListStore;
  /**
   * @deprecated Use the Jotai atom inside src/state directory instead
   */
  content24Store: Content24Store;
  conditionStore: ConditionStore;
  /**
   * @deprecated Use the Jotai atom inside src/state directory instead
   */
  partnersStore: PartnersStore;
  mergeRequestStore: MergeRequestStore;
  /**
   * @deprecated Use the Jotai atom inside src/state directory instead
   */
  partnerStatusStore: PartnerStatusStore;
  conditionsListStore: ConditionsListStore;
  selfcareStore: SelfcareStore;
  conditionVisualizationStore: ConditionVisualizationStore;
  /**
   * @deprecated Use the Jotai atom inside src/state directory instead
   */
  userPermissionsStore: UserPermissionsStore;
  userOriginPermissionListStore: UserOriginPermissionListStore;
  rulesPackagesListStore: RulesPackagesListStore;
  rulesPackageStore: RulesPackageStore;

  constructor(
    flashMessageService: FlashMessageService<string, FlashMessageOptions>,
    jotaiStore: ReturnType<typeof createStore> = createStore()
  ) {
    this.flashMessageService = flashMessageService;

    this.authStore = new AuthStore(this);
    this.userDataStore = new UserDataStore();
    this.userStore = new UserStore();
    this.userListStore = new UserListStore();
    this.practitionersStore = new PractitionersStore();
    this.practitionerFindStore = new PractitionerFindStore();
    this.careUnitsStore = new CareUnitsStore(this);
    this.careUnitStore = new CareUnitStore(this);
    this.practitionerStore = new PractitionerStore(this);
    this.originStore = new OriginStore(this);
    this.originCareUnitsStore = new OriginCareUnitsStore(this);
    this.customizationsStore = new CustomizationsStore(this);
    this.rolesStore = new RolesStore();
    this.practitionerRolesStore = new PractitionerRolesStore(this);
    this.content24Store = new Content24Store(this);
    this.conditionStore = new ConditionStore(this);
    this.partnersStore = new PartnersStore(this);
    this.mergeRequestStore = new MergeRequestStore(this);
    this.partnerStatusStore = new PartnerStatusStore(this);
    this.conditionsListStore = new ConditionsListStore(this);
    this.selfcareStore = new SelfcareStore(this);
    this.conditionVisualizationStore = new ConditionVisualizationStore(this);
    this.userPermissionsStore = new UserPermissionsStore(this, jotaiStore);
    this.rulesPackagesListStore = new RulesPackagesListStore(this);
    this.rulesPackageStore = new RulesPackageStore(this);
    this.originsListStore = new OriginsListStore(this);
    this.userOriginPermissionListStore = new UserOriginPermissionListStore(this);
  }
}
