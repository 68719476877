import mapValues from 'lodash/mapValues';
import pull from 'lodash/pull';
import { observable, IObservableArray, runInAction, computed, toJS, action } from 'mobx';

import {
  fetchOriginCustomization,
  fetchOriginCustomizations,
  updateOriginCustomization,
} from 'api/customizationsApi';
import { LANGS } from 'constants/enums';
import { RESOURCE_TYPES } from 'constants/permissions';
import {
  fetchQuestionCategories,
  QuestionCategory,
  ConditionGroup,
  fetchConditionGroups,
} from 'modules/Content24/Condition/api/code24api';
import {
  fetchConditionExitCategories,
  ExitCategories,
  ExitCategory,
  EXIT_CATEGORY_SOURCE,
  HealthDataCode,
  fetchOriginHealthDataCodes,
  ServiceRequestCode,
  fetchOriginServiceRequestCodes,
} from 'modules/Content24/Condition/api/partnerCode24api';
import {
  CODE24_MODEL_TYPES,
  CODE24_CATEGORIES,
  EXIT_ATTRIBUTES,
  EXIT_ATTRIBUTES_KEYS,
  DISABLED_STATEMENT_GROUPS,
  QUESTION_TYPES,
} from 'modules/Content24/Condition/constants/code24types';
import StateManager from 'stores/abstractStores/StateManager';
import RootStore from 'stores/RootStore';
import { InputOption, Optional, TranslatedText } from 'types/types';
import { sortWithLocale } from 'utils/textUtils';

export default class Content24Store extends StateManager {
  @observable
  exitsCategories?: ExitCategories;

  questionCategories: IObservableArray<QuestionCategory> = observable.array([]);

  private conditionGroups: IObservableArray<ConditionGroup> = observable.array([]);

  availableLanguages: IObservableArray<LANGS> = observable.array([]);
  hiddenConditions: IObservableArray<string> = observable.array([]);
  healthDataCodes: IObservableArray<HealthDataCode> = observable.array([]);
  serviceRequestCodes: IObservableArray<ServiceRequestCode> = observable.array([]);

  @observable
  isLoadingHealthDataCodes = false;

  @observable
  isLoadingServiceRequestCodes = false;

  @observable
  maxOriginConditions = 0;

  @observable
  isDiagnosisCodesShown = false;

  @observable
  code24MedconVersion?: number;

  constructor(private rootStore: RootStore) {
    super();
  }

  @action
  initialize = () =>
    Promise.all([
      this.fetchConditionGroups(),
      this.fetchExitCategories(),
      this.fetchQuestionCategories(),
      this.fetchOriginData(),
    ]);

  @computed
  get questionCategoriesSelectOptions(): InputOption[] {
    return this.questionCategories
      .sort((a, b) => sortWithLocale(a, b, 'questionCategory'))
      .map(({ questionCategory }) => ({
        label: `condition.question-category-${questionCategory}`,
        value: questionCategory,
      }));
  }

  get questionTypeSelectOptions() {
    return Object.keys(QUESTION_TYPES).map(type => ({
      label: `condition.question-type-${type}`,
      value: type,
    }));
  }

  getFilteredQuestionCategoriesSelectOptions = (type: QUESTION_TYPES, category?: string) =>
    this.questionCategories
      .filter(cat => {
        const isMatchingQuestionType = cat.questionTypes.includes(type);
        const isSingleResponse = cat.singleResponse;
        const isSelectedCategory = cat.questionCategory === category;
        const isCategoryAlreadyUsedInThisCondition =
          this.rootStore.conditionStore.conditionStatementsAsOneList
            .filter(stmt => stmt.type === CODE24_MODEL_TYPES.QUESTION)
            .some(question => question['category'] === cat.questionCategory);

        return (
          isMatchingQuestionType &&
          (isSelectedCategory || !isSingleResponse || !isCategoryAlreadyUsedInThisCondition)
        );
      })
      .sort((a, b) => sortWithLocale(a, b, 'questionCategory'))
      .map(({ questionCategory }) => ({
        label: `condition.question-category-${questionCategory}`,
        value: questionCategory,
      }));

  @computed
  get exitAttributes(): { [key in Exclude<EXIT_ATTRIBUTES, 'url'>]: ExitCategory[] } {
    const categories = toJS(this.exitsCategories);

    return {
      [EXIT_ATTRIBUTES.APPOINTMENT_PRIORITY]: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(
        value => ({
          id: value,
          source: 'gavleborg',
          display: {},
        })
      ), // FIXME: Gavleborg only
      [EXIT_ATTRIBUTES.URGENCY]: [],
      [EXIT_ATTRIBUTES.CAPABILITY]: [],
      [EXIT_ATTRIBUTES.LEVEL_OF_CARE]: [],
      [EXIT_ATTRIBUTES.TYPE_OF_CONSULTATION]: [],
      [EXIT_ATTRIBUTES.RESOURCE]: [],
      ...categories,
    };
  }

  @computed
  get exitAttributesTranslations(): {
    [key in Exclude<EXIT_ATTRIBUTES, 'url' | 'appointmentPriority'>]:
      | TranslatedText
      | Record<string, unknown>;
  } {
    const categories = toJS(this.exitsCategories);

    if (categories) {
      return mapValues(
        categories,
        category =>
          category.reduce((accumulator, current) => {
            accumulator[current.id] = current.display;

            return accumulator;
          }, {}) as {
            [key in Exclude<EXIT_ATTRIBUTES, 'url' | 'appointmentPriority'>]: TranslatedText;
          }
      );
    } else {
      return EXIT_ATTRIBUTES_KEYS.reduce((accumulator, key) => {
        if (key !== 'url' && key !== 'appointmentPriority') {
          accumulator[key] = {};
        }

        return accumulator;
      }, {}) as {
        [key in Exclude<EXIT_ATTRIBUTES, 'url' | 'appointmentPriority'>]: Record<string, unknown>;
      };
    }
  }

  @computed
  get hasPartnerSpecificExitAttributes() {
    const categories = toJS(this.exitsCategories);

    if (!categories) {
      return false;
    }

    return Object.keys(categories).some(key =>
      (categories[key] as ExitCategory[]).some(
        ({ source }) => source !== EXIT_CATEGORY_SOURCE.SYSTEM
      )
    );
  }

  private fetchExitCategories = async () => {
    const {
      partnersStore: { partnerId },
    } = this.rootStore;

    if (this.exitsCategories) {
      return;
    }

    this.setLoading();

    try {
      const { data } = await fetchConditionExitCategories(partnerId);

      this.setLoaded();

      runInAction(() => {
        this.exitsCategories = data;
      });
    } catch (e) {
      this.manageException(e);
    }
  };

  private fetchQuestionCategories = async () => {
    if (this.questionCategories.length) {
      return;
    }

    this.setLoading();

    try {
      const { data } = await fetchQuestionCategories();

      this.setLoaded();

      runInAction(() => {
        this.questionCategories.replace(data);
      });
    } catch (e) {
      this.manageException(e);
    }
  };

  manageException = (error: unknown) => {
    this.setError();
    throw error;
  };

  @action
  fetchHealthDataCodes = async () => {
    const { partnerId } = this.rootStore.partnersStore;

    if (this.healthDataCodes.length) {
      return;
    }

    this.isLoadingHealthDataCodes = true;

    try {
      const { data } = await fetchOriginHealthDataCodes(partnerId);

      runInAction(() => {
        this.healthDataCodes.replace(data);
      });
    } finally {
      runInAction(() => {
        this.isLoadingHealthDataCodes = false;
      });
    }
  };

  @action
  fetchServiceRequestCodes = async () => {
    const { partnerId } = this.rootStore.partnersStore;

    if (this.serviceRequestCodes.length) {
      return;
    }

    this.isLoadingServiceRequestCodes = true;

    try {
      const { data } = await fetchOriginServiceRequestCodes(partnerId);

      runInAction(() => {
        this.serviceRequestCodes.replace(data);
      });
    } finally {
      runInAction(() => {
        this.isLoadingServiceRequestCodes = false;
      });
    }
  };

  @action
  private async fetchConditionGroups() {
    const { data } = await fetchConditionGroups();

    runInAction(() => {
      this.conditionGroups.replace(data);
    });
  }

  @computed
  get groups(): ConditionGroup[] {
    // Libraries are not returned admin/code24/group API response
    const libraryGroup: ConditionGroup[] = [
      {
        id: 'libraries',
        category: CODE24_CATEGORIES.LIBRARIES,
        label: { en: 'Library', sv: 'Bibliotek', no: 'Bibliotek', de: 'Bibliothek' },
      },
    ];

    return libraryGroup.concat(this.conditionGroups.slice());
  }

  getGroupsPerCategoryAsSortedSelectOptions(category: CODE24_CATEGORIES, locale: LANGS) {
    return this.groups
      .reduce((accumulator, current) => {
        if (current.category === category) {
          accumulator.push({
            label: current.label,
            value: current.id,
            disabled: DISABLED_STATEMENT_GROUPS.includes(current.id),
          });
        }

        return accumulator;
      }, [] as InputOption<string, TranslatedText>[])
      .sort((a, b) => sortWithLocale(a.label, b.label, locale, locale));
  }

  @computed
  get groupCategories(): Map<string, Optional<CODE24_CATEGORIES>> {
    return new Map(
      this.groups.reduce(
        (accumulator: [string, Optional<CODE24_CATEGORIES>][], group: ConditionGroup) => {
          accumulator.push([group.id, group.category]);
          return accumulator;
        },
        []
      )
    );
  }

  @computed
  get categoryGroups(): Map<CODE24_CATEGORIES, string[]> {
    const map = new Map();

    this.groups.forEach((group: ConditionGroup) => {
      const groups = map.get(group.category) || [];

      map.set(group.category, [...groups, group.id]);
    });

    return map;
  }

  fetchOriginData = async () => {
    const {
      partnersStore: { partnerId },
    } = this.rootStore;

    this.setLoading();

    try {
      const { data } = await fetchOriginCustomizations(partnerId, partnerId);
      const originCustomizations = new Set([
        'AVAILABLE_LANGUAGES',
        'HIDDEN_CONDITIONS',
        'CODE24_MEDCON_VERSION',
        'CODE24_MAX_ORIGIN_CONDITION_CHANGES',
        'CODE24_SHOW_DIAGNOSIS_CODES',
      ]);
      // To be refactored
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const defaultCustomizationValues: Record<string, any> = {};

      for (let i = 0; i < data.length; i += 1) {
        if (!originCustomizations.size) {
          break;
        }
        const customization = data[i];
        const { key, value } = customization;

        if (originCustomizations.has(key)) {
          defaultCustomizationValues[key] = value;
          originCustomizations.delete(key);
        }
      }

      runInAction(() => {
        this.availableLanguages.replace(defaultCustomizationValues['AVAILABLE_LANGUAGES'] ?? []);
        this.hiddenConditions.replace(defaultCustomizationValues['HIDDEN_CONDITIONS'] ?? []);
        this.code24MedconVersion = defaultCustomizationValues['CODE24_MEDCON_VERSION'] ?? undefined;
        this.maxOriginConditions =
          defaultCustomizationValues['CODE24_MAX_ORIGIN_CONDITION_CHANGES'] ?? 0;
        this.isDiagnosisCodesShown = !!defaultCustomizationValues['CODE24_SHOW_DIAGNOSIS_CODES'];
      });
    } finally {
      this.setLoaded();
    }
  };

  @action
  updateHiddenConditionsCustomization = async (isHidden: boolean, conditionId: string) => {
    try {
      const {
        partnersStore: { partnerId },
      } = this.rootStore;

      // First, optimistic update
      if (isHidden) {
        this.hiddenConditions.push(conditionId);
      } else {
        this.hiddenConditions.remove(conditionId);
      }

      const {
        data: { value: customizationValueToUpdate = [] },
        data: customization,
      } = await fetchOriginCustomization(partnerId, partnerId, 'HIDDEN_CONDITIONS');

      if (isHidden) {
        customizationValueToUpdate.push(conditionId);
      } else {
        pull(customizationValueToUpdate, conditionId);
      }

      const invalidatedValue: string[] = Array.from(new Set(customizationValueToUpdate));

      const updatedCustomization = {
        ...customization,
        value: invalidatedValue,
      };

      const { data: newCustomization } = await updateOriginCustomization(
        updatedCustomization,
        partnerId,
        partnerId
      );

      runInAction(() => {
        this.hiddenConditions.replace(newCustomization.value);
      });
    } catch {
      // Say goodbye to the optimistic update
      if (isHidden) {
        this.hiddenConditions.remove(conditionId);
      } else {
        this.hiddenConditions.push(conditionId);
      }
    }
  };

  getCanEditContent24Resource(
    resourceType: RESOURCE_TYPES.CODE24 | RESOURCE_TYPES.HOOKS | RESOURCE_TYPES.SELFCARE
  ) {
    const {
      partnersStore: { partnerId },
    } = this.rootStore;

    return this.rootStore.userPermissionsStore.getPermission(
      {
        // Currently there's no way to set partner scope permission in Manage24
        // Details: https://platform24.atlassian.net/browse/AX-30158
        originId: partnerId,
        resourceType,
      },
      this.rootStore.userPermissionsStore.canEditCurrentPartner
    );
  }

  @computed
  get canEditSelfcareAdvice() {
    const {
      partnersStore: { isReadOnlyModeEnabled },
    } = this.rootStore;

    return this.getCanEditContent24Resource(RESOURCE_TYPES.SELFCARE) && !isReadOnlyModeEnabled;
  }

  @computed
  get canAddContent24() {
    const {
      partnersStore: { isReadOnlyModeEnabled },
    } = this.rootStore;

    return this.getCanEditContent24Resource(RESOURCE_TYPES.CODE24) && !isReadOnlyModeEnabled;
  }

  @computed
  get canEditContent24() {
    const {
      partnersStore: { isReadOnlyModeEnabled },
    } = this.rootStore;

    return this.getCanEditContent24Resource(RESOURCE_TYPES.HOOKS) && !isReadOnlyModeEnabled;
  }

  @computed
  get healthDataCodeTypeMap() {
    return new Map(this.healthDataCodes.map(({ id, type }) => [id, type]));
  }
}
