import { theme } from '@platform24/admin-ui-utils';
import { ConfigProvider } from 'antd';
import enUS from 'antd/es/locale/en_US';
import nbNO from 'antd/es/locale/nb_NO';
import nlNL from 'antd/es/locale/nl_NL';
import svSE from 'antd/es/locale/sv_SE';
import * as React from 'react';
import { useIntl } from 'react-intl';

const translationsMap = {
  en: enUS,
  sv: svSE,
  no: nbNO,
  nl: nlNL,
} as Record<string, Locale>;

interface Props {
  children: React.ReactNode;
}

const AntdConfigProvider: React.FC<Props> = ({ children }: Props) => {
  const intl = useIntl();
  const localProp = {
    local: translationsMap[intl.locale],
    theme,
  };

  return <ConfigProvider {...localProp}>{children}</ConfigProvider>;
};

export default AntdConfigProvider;
