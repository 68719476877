import pick from 'lodash/pick';
import { observable, runInAction } from 'mobx';

import { IDENTITIES } from 'constants/practitioner';
import StateManager from 'stores/abstractStores/StateManager';

import { UserType, fetchUser, updateUser } from '../user.api';

export class UserStore extends StateManager {
  ALLOWED_PROPERTIES = [
    'id',
    'externalId',
    'externalIdType',
    'givenName',
    'middleAndSurname',
    'title',
    'externalIds',
    'email',
    'mobileNumber',
  ];

  @observable data: UserType = {
    id: '',
    externalId: '',
    externalIdType: IDENTITIES.HSA_ID,
    givenName: '',
    middleAndSurname: '',
    title: '',
    externalIds: [{ externalIdType: IDENTITIES.HSA_ID, externalId: '' }],
    email: '',
    mobileNumber: '',
  };

  // To be refactored
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cleanData = (data: Record<string, any>): UserType => {
    // BE returns more than needed, we want to keep our data clean
    return pick(data, this.ALLOWED_PROPERTIES) as UserType;
  };

  fetch = async (id: string) => {
    if (this.data.id === id) {
      return;
    }

    this.setLoading();

    try {
      const { data } = await fetchUser(id);

      runInAction(() => {
        this.data = this.cleanData(data);
      });

      this.setLoaded();
    } catch (e) {
      this.manageException(e);
    }
  };

  update = async (user: UserType) => {
    this.setSaving();

    try {
      const cleanedUser = this.cleanData(user);
      const { data } = await updateUser(cleanedUser);

      runInAction(() => {
        this.data = this.cleanData(data);
      });

      this.setLoaded();
    } catch (e) {
      this.manageException(e);
    }
  };

  manageException = (error: unknown) => {
    this.setError();
    throw error;
  };
}
