import { atom } from 'jotai';

import { ACCESS_LEVEL } from 'api/permissionsApi';
import { jotaiStore as store } from 'config/jotaiStore';
import { LOCAL_STORAGE_KEYS } from 'constants/localStorageKeys';
import { RESOURCE_TYPES } from 'constants/permissions';
import { getPermissionStringWildCardCombinations } from 'utils/textUtils';

export type PermissionQueryConfig = {
  resourceType: RESOURCE_TYPES;
  accessLevel?: ACCESS_LEVEL;
  originId?: string;
  careProviderId?: string;
  careUnitId?: string;
  resourceValue?: string;
};

const scope = atom<Map<string, ACCESS_LEVEL>>(new Map());
const menuAccess = atom<Map<string, boolean>>(new Map());

export const userPermissionsPrivateAtoms = {
  scope,
  menuAccess,
};

const isEnabled = atom(get => get(scope).size > 0);

const getScopeValue = (basePath: string, accessLevel: ACCESS_LEVEL, resourceValue?: string) => {
  const isReadAccess = accessLevel === ACCESS_LEVEL.READ;
  const scopeWildcardPaths = getPermissionStringWildCardCombinations(basePath);

  for (let i = 0; i < scopeWildcardPaths.length; i += 1) {
    const path = scopeWildcardPaths[i];
    const resourceWildcardValue = store.get(scope).get(`/${path}/**`);

    if (!!resourceWildcardValue && (isReadAccess || resourceWildcardValue === ACCESS_LEVEL.WRITE)) {
      return true;
    }

    const value = store.get(scope).get(`/${path}/${resourceValue}`);

    if (!!value && (isReadAccess || value === ACCESS_LEVEL.WRITE)) {
      return true;
    }
  }

  return false;
};

const getSystemScopeValue = (
  resourceType: RESOURCE_TYPES,
  accessLevel: ACCESS_LEVEL,
  resourceValue?: string
) => {
  // System scope paths are treated a bit different,
  // that's why getScopeValue is not reused here.
  const isReadAccess = accessLevel === ACCESS_LEVEL.READ;
  // First two work the same, last one is for super admins (access all resources)
  const scopeWildcardPaths = [`/${resourceType}`, `/${resourceType}/**`, `/**`];

  const scopeValue = store.get(scope);
  for (let i = 0; i < scopeWildcardPaths.length; i += 1) {
    const path = scopeWildcardPaths[i];
    const resoureWildcardValue = scopeValue.get(path);

    if (!!resoureWildcardValue && (isReadAccess || resoureWildcardValue === ACCESS_LEVEL.WRITE)) {
      return true;
    }
  }

  const value = scopeValue.get(`/${resourceType}/${resourceValue}`);

  return !!value && (isReadAccess || value === ACCESS_LEVEL.WRITE);
};

const getPartnerScopeValue = (
  resourceType: RESOURCE_TYPES,
  accessLevel: ACCESS_LEVEL,
  partnerId: string,
  resourceValue?: string
) => getScopeValue(`partners/${partnerId}/${resourceType}`, accessLevel, resourceValue);

const getOriginScopeValue = (
  resourceType: RESOURCE_TYPES,
  accessLevel: ACCESS_LEVEL,
  partnerId: string,
  originId: string,
  resourceValue?: string
) =>
  getScopeValue(
    `partners/${partnerId}/origins/${originId}/${resourceType}`,
    accessLevel,
    resourceValue
  );

const getCareProviderScopeValue = (
  resourceType: RESOURCE_TYPES,
  accessLevel: ACCESS_LEVEL,
  partnerId: string,
  careProviderId: string,
  resourceValue?: string
) =>
  getScopeValue(
    `partners/${partnerId}/careproviders/${careProviderId}/${resourceType}`,
    accessLevel,
    resourceValue
  );

const getCareUnitScopeValue = (
  resourceType: RESOURCE_TYPES,
  accessLevel: ACCESS_LEVEL,
  partnerId: string,
  careProviderId: string,
  careUnitId: string,
  resourceValue?: string
) =>
  getScopeValue(
    `partners/${partnerId}/careproviders/${careProviderId}/careunits/${careUnitId}/${resourceType}`,
    accessLevel,
    resourceValue
  );

// --- getters
const getPermission = (
  {
    resourceType,
    accessLevel = ACCESS_LEVEL.WRITE,
    originId,
    careProviderId,
    careUnitId,
    resourceValue,
  }: PermissionQueryConfig,
  // utility argument meant only for transition period
  fallback = false
) => {
  if (!store.get(isEnabled)) {
    return fallback;
  }

  const systemScopeValue = getSystemScopeValue(resourceType, accessLevel, resourceValue);

  if (systemScopeValue) {
    return true;
  }

  const partnerId = localStorage.getItem(LOCAL_STORAGE_KEYS.PARTNER_ID);
  if (!partnerId) {
    return false;
  }

  const partnerScopeValue = getPartnerScopeValue(
    resourceType,
    accessLevel,
    partnerId,
    resourceValue
  );
  if (partnerScopeValue) {
    return true;
  }

  if (originId) {
    return getOriginScopeValue(resourceType, accessLevel, partnerId, originId, resourceValue);
  }

  if (careProviderId) {
    const careProviderScopeValue = getCareProviderScopeValue(
      resourceType,
      accessLevel,
      partnerId,
      careProviderId,
      resourceValue
    );

    if (careProviderScopeValue) {
      return true;
    }

    if (careUnitId) {
      return getCareUnitScopeValue(
        resourceType,
        accessLevel,
        partnerId,
        careProviderId,
        careUnitId,
        resourceValue
      );
    }
  }

  return false;
};

export const userPermissionsSelectors = {
  getPermission,
};
