import { LoadingOutlined } from '@ant-design/icons';
import Spin from 'antd/lib/spin';
import { observer } from 'mobx-react';
import React, { ComponentType, Component, ComponentPropsWithRef } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
// imported from lib due to https://github.com/DocSpring/craco-antd/issues/10

import RootStoreContext from 'context/RootStoreContext';
import UserPermissionsStore from 'stores/UserPermissionsStore';

const withRouteGuard =
  (canActivate: (userPermissionsStore: UserPermissionsStore) => boolean) =>
  // To be refactored
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  <P extends RouteComponentProps<any>>(
    // To be refactored
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    WrappedComponent: ComponentType<ComponentPropsWithRef<any>>
  ) =>
    observer(
      class WithAuthorization extends Component<P> {
        static contextType = RootStoreContext;

        render() {
          const { userPermissionsStore } = this.context as any; /** todo: fix any */

          if (userPermissionsStore.isLoading()) {
            return <Spin size="large" indicator={<LoadingOutlined spin />} data-testid="spinner" />;
          }

          const authorized = canActivate(userPermissionsStore);

          if (authorized) {
            return <WrappedComponent {...this.props} />;
          }

          return <Redirect to="/" />;
        }
      }
    );

export default withRouteGuard;
