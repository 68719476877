import { Button, Typography } from 'antd';
import {
  EditorState,
  RichUtils,
  getDefaultKeyBinding,
  ContentBlock,
  convertFromRaw,
  convertToRaw,
  CompositeDecorator,
  ContentState,
  Editor,
  RawDraftContentState,
} from 'draft-js';
import 'draft-js/dist/Draft.css';
import React, { Component, KeyboardEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import { css, styled } from 'styled-components';

import { RICH_TEXT_CONTENT_TYPES } from 'constants/text';

import { AddImageModal, AddImageControl } from './components/AddImageControl';
import { getImageComponent } from './components/Image';
import InlineStyleControls from './components/InlineStyleControls';
import LinkControls from './components/LinkControl';
import Link from './components/LinkControl/Link';
import ListStyleControls from './components/ListStyleControls';
import ParagraphStyleControls from './components/ParagraphStyleControls';
import { htmlToDraft, draftToHtml, convertMdToDraft, convertDraftToMd } from './utils';

const MAX_LIST_INDENT_DEPTH = 4;

const Styled = {
  Editor: styled.div<{ $hasError?: boolean }>`
    background-color: var(--color-white);
    border: 1px solid var(--color-border-dark);
    border-radius: var(--border-radius);
    padding: 11px;
    min-height: 150px;

    ${({ $hasError }) =>
      $hasError &&
      css`
        border-color: var(--color-red);
      `}
  `,
  ControlPanel: styled.div`
    display: inline-block;
    margin: 0 11px 11px 0;
  `,
  ErrorText: styled(Typography.Text)`
    color: var(--color-red);
  `,
};

interface RichTextEditorProps {
  initialValue: string;
  onChange: (markdownString: string) => void;
  isDisabled?: boolean;
  contentType?: RICH_TEXT_CONTENT_TYPES;
  // Whether to show paragraph controls
  paragraphControls?: boolean;
  // Whether to show image controls (only Markdown content supports images)
  imageControls?: boolean;
  required?: boolean;
}

interface State {
  editorState: EditorState;
  imageEntityKey?: string;
}

class RichTextEditor extends Component<RichTextEditorProps, State> {
  static defaultProps = {
    contentType: RICH_TEXT_CONTENT_TYPES.MARKDOWN,
  };

  editor?: Editor | null;
  editorContainer?: HTMLDivElement | null;
  state: State = {
    editorState: EditorState.createEmpty(),
  };

  constructor(props: RichTextEditorProps) {
    super(props);

    const content = props.initialValue;
    const isMarkdownContent =
      !props.contentType || props.contentType === RICH_TEXT_CONTENT_TYPES.MARKDOWN;
    const rawContentState = isMarkdownContent ? convertMdToDraft(content) : htmlToDraft(content);
    const contentState = convertFromRaw(rawContentState);
    const decorator = new CompositeDecorator([
      {
        strategy: this.findLinkEntities,
        component: Link,
      },
      {
        strategy: this.findImageEntities,
        component: getImageComponent(this.handleImageClick),
      },
    ]);

    this.state = {
      editorState: EditorState.createWithContent(contentState, decorator),
    };
  }

  convertToOutput(content: RawDraftContentState) {
    const conversionMethod =
      this.props.contentType === RICH_TEXT_CONTENT_TYPES.MARKDOWN ? convertDraftToMd : draftToHtml;

    return conversionMethod(content);
  }

  findLinkEntities = (
    contentBlock: ContentBlock,
    callback: (start: number, end: number) => void,
    contentState: ContentState
  ) => {
    contentBlock.findEntityRanges(character => {
      const entityKey = character.getEntity();
      return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK';
    }, callback);
  };

  findImageEntities = (
    contentBlock: ContentBlock,
    callback: (start: number, end: number) => void,
    contentState: ContentState
  ) => {
    contentBlock.findEntityRanges(character => {
      const entityKey = character.getEntity();
      return entityKey !== null && contentState.getEntity(entityKey).getType() === 'IMAGE';
    }, callback);
  };

  handleImageClick = (imageEntityKey: string) => {
    this.setState({ imageEntityKey });
  };

  handleCancelAddImage = () => {
    this.setState({ imageEntityKey: undefined });
  };

  handleUpdateImage = (editorState: EditorState) => {
    const contentState = editorState.getCurrentContent();
    const rawValue = convertToRaw(contentState);
    const value = this.convertToOutput(rawValue);

    this.setState({ editorState, imageEntityKey: undefined });
    this.props.onChange(value);
  };

  handleChange = (editorState: EditorState) => {
    // handleChange is fired on various state changes, not only when content actually changes,
    // so to trigger handleChange prop properly, we have to compare previous and current content.
    const content = editorState.getCurrentContent();
    const previousContent = this.state.editorState.getCurrentContent();
    const rawValue = convertToRaw(content);
    const rawPreviousValue = convertToRaw(previousContent);
    const value = this.convertToOutput(rawValue);
    const previousValue = this.convertToOutput(rawPreviousValue);
    const isContentModified = value !== previousValue;

    this.setState({ editorState });

    isContentModified && this.props.onChange(value);
  };

  setEditor = (editor: Editor | null) => {
    this.editor = editor;
  };

  setEditorContainer = (container: HTMLDivElement | null) => {
    this.editorContainer = container;
  };

  handleKeyCommand = (command: string, editorState: EditorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    const imageEntityBlockProhibitedCommands = ['split-block', 'backspace'];

    // Draft.js has buggy image entity block support when it comes to editing it
    // along with "regular" siblings. We need to prevent user from using Backspace
    // and Enter when image entity block is current selection.
    if (imageEntityBlockProhibitedCommands.includes(command)) {
      const selection = editorState.getSelection();
      const block = editorState.getCurrentContent().getBlockForKey(selection.getStartKey());
      const entityKey = block.getEntityAt(selection.getStartOffset());

      if (entityKey) {
        const entity = editorState.getCurrentContent().getEntity(entityKey);

        if (entity.getType() === 'IMAGE') {
          return 'handled';
        }
      }
    }

    if (newState) {
      this.handleChange(newState);
      return 'handled';
    }

    return 'not-handled';
  };

  mapKeyToEditorCommand = (event: KeyboardEvent<Element>) => {
    // handle indent change in list with TAB key
    if (event.key === 'Tab') {
      const newEditorState = RichUtils.onTab(event, this.state.editorState, MAX_LIST_INDENT_DEPTH);

      if (newEditorState !== this.state.editorState) {
        this.handleChange(newEditorState);
      }

      return null;
    }
    return getDefaultKeyBinding(event);
  };

  handleBlockTypeToggle = (blockType: string) => {
    this.handleChange(RichUtils.toggleBlockType(this.state.editorState, blockType));
  };

  handleInlineStyleToggle = (inlineStyle: string) => {
    this.handleChange(RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle));
  };

  // This sets focus on editor when empty container is clicked
  handleEditorClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === this.editorContainer) {
      this.editor?.focus();
    }
  };

  render() {
    const { editorState } = this.state;
    const { isDisabled } = this.props;

    const hasRequiredError = this.props.required && !editorState.getCurrentContent().hasText();

    return (
      <>
        <Styled.Editor
          onClick={this.handleEditorClick}
          ref={this.setEditorContainer}
          $hasError={hasRequiredError}
        >
          {this.props.paragraphControls && (
            <Styled.ControlPanel>
              <ParagraphStyleControls
                editorState={editorState}
                onToggle={this.handleBlockTypeToggle}
                isDisabled={isDisabled}
              />
            </Styled.ControlPanel>
          )}
          <Styled.ControlPanel>
            <ListStyleControls
              editorState={editorState}
              onToggle={this.handleBlockTypeToggle}
              isDisabled={isDisabled}
            />
          </Styled.ControlPanel>
          <Styled.ControlPanel>
            <InlineStyleControls
              editorState={editorState}
              onToggle={this.handleInlineStyleToggle}
              isDisabled={isDisabled}
            />
          </Styled.ControlPanel>
          <Styled.ControlPanel>
            <Button.Group>
              <LinkControls
                editorState={editorState}
                onEditLink={this.handleChange}
                isDisabled={isDisabled}
              />
              {this.props.imageControls &&
                this.props.contentType === RICH_TEXT_CONTENT_TYPES.MARKDOWN && (
                  <AddImageControl onClick={this.handleImageClick} editorState={editorState} />
                )}
            </Button.Group>
          </Styled.ControlPanel>
          <Editor
            ref={this.setEditor}
            handleKeyCommand={this.handleKeyCommand}
            editorState={editorState}
            keyBindingFn={this.mapKeyToEditorCommand}
            onChange={this.handleChange}
            readOnly={this.props.isDisabled}
          />
        </Styled.Editor>
        {hasRequiredError ? (
          <Styled.ErrorText>
            <FormattedMessage id="general.required-field" />
          </Styled.ErrorText>
        ) : null}
        <AddImageModal
          imageEntityKey={this.state.imageEntityKey}
          editorState={editorState}
          onChange={this.handleUpdateImage}
          onCancel={this.handleCancelAddImage}
        />
      </>
    );
  }
}

export default RichTextEditor;
