import { Modal, Divider } from 'antd';
import { Formik, FormikValues } from 'formik';
import { Form, Select } from 'formik-antd';
import { Observer } from 'mobx-react';
import React, { FunctionComponent, useCallback } from 'react';
import { FormattedMessage, injectIntl, useIntl, WrappedComponentProps } from 'react-intl';
import * as Yup from 'yup';

import FormActionButtons from 'components/FormActionButtons';
import { InputOption, UserRole } from 'types/types';

interface Props extends WrappedComponentProps {
  data?: UserRole;
  roles: InputOption[];
  practitioners: InputOption[];
  isSaving: boolean;
  isVisible: boolean;
  isAddMode?: boolean;
  onCancel: () => any;
  onSubmit: (data: UserRole) => any;
  onReset?: (data: UserRole) => void;
}

const AddEditRole: FunctionComponent<Props> = ({
  isSaving,
  onCancel,
  roles,
  data = {
    practitionerId: '',
    practitionerName: '',
    role: '',
  },
  onSubmit,
  onReset,
  practitioners,
  isAddMode,
  isVisible,
}) => {
  const intl = useIntl();

  const validationSchema = Yup.object().shape({
    practitioner: Yup.string().required(
      intl.formatMessage({
        id: 'add-roles-form.error.practitioner-required',
      })
    ),
    role: Yup.string().required(
      intl.formatMessage({
        id: 'add-roles-form.error.role-required',
      })
    ),
  });

  const handleSubmit = useCallback(
    (values: FormikValues) => {
      const {
        practitioner: { label, key },
        role,
      } = values;
      onSubmit({ practitionerId: key, practitionerName: label, role });
    },
    [onSubmit]
  );

  const initialValues = {
    practitioner: { key: data.practitionerId, label: data.practitionerName },
    role: data.role,
  };

  const handleReset = useCallback(() => {
    onReset && onReset(data);
  }, [onReset, data]);

  return (
    <Observer>
      {() => (
        <Modal
          open={isVisible}
          destroyOnClose
          title={
            isAddMode ? (
              <FormattedMessage id="add-roles-form.add-btn" />
            ) : (
              <FormattedMessage id="general.edit" />
            )
          }
          footer={null}
          closable={false}
        >
          <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
          >
            {({ isValid, dirty }) => (
              <Form>
                <Form.Item
                  label={<FormattedMessage id="add-roles-form.header.practitioner" />}
                  name="practitioner"
                >
                  <Select
                    name="practitioner"
                    showSearch
                    labelInValue
                    optionFilterProp="label"
                    options={practitioners}
                  />
                </Form.Item>
                <Form.Item label={<FormattedMessage id="add-roles-form.header.role" />} name="role">
                  <Select name="role" options={roles} />
                </Form.Item>
                <Divider />
                <FormActionButtons
                  isSaving={isSaving}
                  isDisabled={isSaving}
                  isValid={isValid}
                  onCancel={onCancel}
                  onReset={onReset && handleReset}
                  showCancelConfirm={dirty}
                />
              </Form>
            )}
          </Formik>
        </Modal>
      )}
    </Observer>
  );
};

export default injectIntl(AddEditRole);
