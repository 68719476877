import { Typography } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { toJS } from 'mobx';
import { Observer } from 'mobx-react';
import * as React from 'react';
import { useEffect, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';

import { Practitioner } from 'api/practitionerApi';
import { DEFAULT_FLASH_MESSAGE_TIMEOUT } from 'constants/general';
import { ROLE_MENUITEMS } from 'constants/permissions';
import RootStoreContext from 'context/RootStoreContext';
import PractitionerData from 'modules/Practitioner/PractitionerData/PractitionerData';

import styles from './RolesEdit.module.css';
import PersonalRoles from '../PersonalRoles';

const RolesEdit = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const intl = useIntl();

  const {
    practitionerStore,
    practitionerFindStore,
    partnersStore,
    userPermissionsStore,
    practitionerRolesStore,
    flashMessageService,
    userDataStore: userStore,
  } = useContext(RootStoreContext);

  const handleSubmit = async (data: Practitioner) => {
    try {
      const newPractitioner = await practitionerStore.save(data);

      flashMessageService.success(
        intl.formatMessage({
          id: data.id
            ? 'practitioner.practitioner-updated-message'
            : 'practitioner.new-practitioner-created-message',
        })
      );

      if (!data.id && newPractitioner.id) {
        history.push(`/roles/${newPractitioner.id}/edit`);
      }
    } catch (error) {
      flashMessageService.translatedError('fallback.header');
    }
  };

  useEffect(() => {
    partnersStore.fetchCountryCallingCodes();
    try {
      if (id) {
        practitionerStore.fetch(id);
      } else {
        practitionerStore.clear();
      }
    } catch (e) {
      flashMessageService.translatedError(
        'practitioner.errors.not-found',
        { autoClose: DEFAULT_FLASH_MESSAGE_TIMEOUT },
        { id }
      );
      history.push('/roles');
    }

    return () => {
      practitionerStore.clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const isEditMode = !!id;

  return (
    <div className={styles.container}>
      <Observer>
        {() => {
          const practitionerData = toJS(practitionerStore.data) as Practitioner;

          const hasRoleAtUserCareUnit =
            userPermissionsStore.isSuperAdmin ||
            (!!practitionerData &&
              practitionerRolesStore.allRoles.some(({ careUnitId }) =>
                userStore.userCareUnits.some(({ id }) => careUnitId === id)
              ));
          const isClinicUserAdminOrSuperAdminOrConfigAdmin = userPermissionsStore.getSideBarAccess(
            ROLE_MENUITEMS.CLINIC_USER_ADMIN,
            ROLE_MENUITEMS.CONFIG_ADMIN,
            ROLE_MENUITEMS.SUPER_ADMIN
          );
          return (
            <PractitionerData
              initialValues={practitionerData}
              countryCallingCodes={toJS(partnersStore.countryCallingCodes)}
              externalIdValidationFunction={practitionerFindStore.findByExternalId}
              onSubmit={handleSubmit}
              hasRoleAtUserCareUnit={hasRoleAtUserCareUnit}
              isFullyEditable={
                userPermissionsStore.isSuperAdmin ||
                isEmpty(practitionerData) ||
                isClinicUserAdminOrSuperAdminOrConfigAdmin
              }
              isLoading={practitionerStore.isLoading() || practitionerStore.isSaving()}
            />
          );
        }}
      </Observer>

      {isEditMode && (
        <>
          <Typography.Title level={3}>
            <FormattedMessage id="roles.header" />
          </Typography.Title>
          <PersonalRoles />
        </>
      )}
    </div>
  );
};

export default RolesEdit;
