import { ColumnFilterItem } from 'antd/lib/table/interface';
import { AxiosError } from 'axios';
import sortBy from 'lodash/sortBy';
import { observable, computed, runInAction, IObservableArray, action } from 'mobx';

import {
  Customization,
  fetchOriginCustomizations,
  updateOriginCustomization,
  fetchCareUnitCustomizations,
  updateCareUnitCustomization,
} from 'api/customizationsApi';

import StateManager from './abstractStores/StateManager';
import RootStore from './RootStore';

export default class CustomizationsStore extends StateManager {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    super();
    this.rootStore = rootStore;
  }

  @observable originCustomizations: IObservableArray<Customization> = observable.array([]);
  @observable careUnitCustomizations: IObservableArray<Customization> = observable.array([]);

  fetchOriginCustomizations = async (originId: string) => {
    this.setLoading();
    try {
      const { data } = await fetchOriginCustomizations(
        this.rootStore.partnersStore.partnerId,
        originId
      );

      runInAction(() => {
        this.originCustomizations.replace(data);
      });

      this.setLoaded();
    } catch (e) {
      this.manageException(e);
    }
  };

  @action
  clearOriginCustomizations() {
    this.originCustomizations.replace([]);
  }

  updateOriginCustomization = async (updatedData: Customization, originId: string) => {
    this.setSaving();

    try {
      const { data } = await updateOriginCustomization(
        updatedData,
        this.rootStore.partnersStore.partnerId,
        originId
      );

      runInAction(() => {
        this.originCustomizations.replace(
          this.originCustomizations.map(elem => (elem.key === updatedData.key ? data : elem))
        );
      });

      this.setLoaded();
    } catch (e) {
      this.manageException(e);
    }
  };

  fetchCareUnitCustomizations = async (careProviderId: string, careUnitId: string) => {
    this.setLoading();
    try {
      const { data } = await fetchCareUnitCustomizations(
        this.rootStore.partnersStore.partnerId,
        careProviderId,
        careUnitId
      );

      runInAction(() => {
        this.careUnitCustomizations.replace(data);
      });

      this.setLoaded();
    } catch (e) {
      this.manageException(e);
    }
  };

  updateCareUnitCustomization = async (
    updatedData: Customization,
    careProviderId: string,
    careUnitId: string
  ) => {
    this.setSaving();

    try {
      const { data } = await updateCareUnitCustomization(
        updatedData,
        this.rootStore.partnersStore.partnerId,
        careProviderId,
        careUnitId
      );

      runInAction(() => {
        this.careUnitCustomizations.replace(
          this.careUnitCustomizations.map(elem => (elem.key === updatedData.key ? data : elem))
        );
      });

      this.setLoaded();
    } catch (e) {
      this.manageException(e);
    }
  };

  @computed
  get careUnitCustomizationsSorted() {
    const customizations = sortBy(this.careUnitCustomizations, c => (c.header || '').toLowerCase());

    if (this.rootStore.userPermissionsStore.isSuperAdmin) {
      return customizations;
    }

    return customizations.filter(({ tags }) => !tags.includes('manage24'));
  }

  @computed
  get originCustomizationsSorted() {
    const customizations = sortBy(this.originCustomizations, c => (c.header || '').toLowerCase());

    if (this.rootStore.userPermissionsStore.isSuperAdmin) {
      return customizations;
    }

    return customizations.filter(({ tags }) => !tags.includes('manage24'));
  }

  @computed
  get originCustomizationsTagFilters(): ColumnFilterItem[] {
    return this.getTagFilterItems(this.originCustomizations);
  }

  @computed
  get careUnitsCustomizationsTagFilters(): ColumnFilterItem[] {
    return this.getTagFilterItems(this.careUnitCustomizations);
  }

  getTagFilterItems = (customizations: Customization[]): ColumnFilterItem[] => {
    const tags = customizations.reduce((accumulator: string[], current: Customization) => {
      if (current.tags.length) {
        return [...accumulator, ...current.tags];
      }
      return accumulator;
    }, []);

    // uniqe tags
    return [...new Set(tags)].map(tag => ({ text: tag, value: tag }));
  };

  manageException = <E = AxiosError>(error: E) => {
    this.setError();
    throw error;
  };
}
