import Cookie from 'js-cookie';

export const COOKIE_AUTH_KEY = 'auth';

export const setToken = (token: string) => {
  Cookie.set(COOKIE_AUTH_KEY, token, { secure: true });
};

export const getToken = () => {
  return Cookie.get(COOKIE_AUTH_KEY);
};

export const removeToken = () => {
  Cookie.remove(COOKIE_AUTH_KEY);
};
