import { observable, IObservableArray, action, computed, ObservableMap, runInAction } from 'mobx';
import { ChangeEvent } from 'react';

import RootStore from 'stores/RootStore';

export interface CareProvidersListItem {
  id: string;
  name: string;
  children?: CareProvidersListItem[];
  [key: string]: any;
}

export default class CareProvidersListStore {
  @observable searchTerm = '';
  @observable expandedCareProvidersMap: ObservableMap<string, CareProvidersListItem[]> =
    new ObservableMap();
  @observable expandedRowKeys: IObservableArray<string> = observable.array([]);

  constructor(private rootStore: RootStore) {}

  @computed
  get careProvidersList() {
    if (!this.searchTerm) {
      return this.careProviders;
    }

    const matchingCareUnitsMap = this.buildMatchingCareUnitsMap();

    return this.careProviders
      .filter(
        item =>
          // preserve the careProvider, if at least one of its care units matches the search term
          matchingCareUnitsMap.get(item.id) ||
          // or the careProvider itself matches the term
          item.name.toLowerCase().includes(this.searchTerm) ||
          item.id.toLowerCase().includes(this.searchTerm)
      )
      .map(item => {
        // if there are matching care units, add only those to the children prop
        if (matchingCareUnitsMap.get(item.id)?.length) {
          return {
            ...item,
            children: matchingCareUnitsMap.get(item.id),
          };
        }
        return item;
      });
  }

  private buildMatchingCareUnitsMap = () => {
    return new Map(
      Array.from(this.expandedCareProvidersMap).filter(expandedCareProvider => {
        expandedCareProvider[1] = expandedCareProvider[1].filter(
          item =>
            item.name.toLowerCase().includes(this.searchTerm) ||
            item.id.toLowerCase().includes(this.searchTerm)
        );

        return expandedCareProvider[1].length > 0;
      })
    );
  };

  @computed
  get careProviders() {
    return this.rootStore.partnersStore.careProviders.map(careProvider => ({
      ...careProvider,
      name: careProvider.name || '',
      children: this.expandedCareProvidersMap.get(careProvider.id) || [],
    }));
  }

  @action
  handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.searchTerm = event.target.value.toLowerCase();
  };

  handleExpand = (expanded: boolean, careProviderId: string) => {
    this.setExpandedRowKeys(expanded, careProviderId);
    this.fetchExpandedCareProvider(careProviderId);
  };

  @action
  setExpandedRowKeys = (expanded: boolean, careProviderId: string) => {
    if (expanded) {
      this.expandedRowKeys.push(careProviderId);
    } else {
      this.expandedRowKeys.replace(this.expandedRowKeys.filter(elem => elem !== careProviderId));
    }
  };

  fetchExpandedCareProvider = async (careProviderId: string) => {
    const { careUnitsStore } = this.rootStore;

    if (this.expandedCareProvidersMap.get(careProviderId)) {
      return;
    }

    await careUnitsStore.fetchCareUnits(careProviderId);

    runInAction(() => {
      if (careUnitsStore.currentCareUnits.length) {
        this.expandedCareProvidersMap.set(
          careProviderId,
          careUnitsStore.currentCareUnits.map(careUnit => ({
            id: careUnit.id,
            name: careUnit.name || '',
          }))
        );
      }
    });
  };
}
