import { Typography } from 'antd';
import React from 'react';
import { styled } from 'styled-components';

import { PopoverTooltip } from './fields/PopoverTooltip';

const Styled = {
  Container: styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 16px;

    h4 {
      margin-bottom: 0;
      font-weight: normal;
    }
  `,
};

export const CardTitle = ({
  children,
  tooltip,
  extra,
}: {
  children: React.ReactNode;
  tooltip: React.ReactNode;
  extra?: React.ReactNode;
}) => (
  <Styled.Container>
    <Typography.Title level={4}>{children}</Typography.Title>

    <PopoverTooltip>{tooltip}</PopoverTooltip>

    {extra}
  </Styled.Container>
);
