import { Col, Divider, Row, Form as AntdForm } from 'antd';
import { Formik } from 'formik';
import { Input, Form, Select } from 'formik-antd';
import { Observer } from 'mobx-react';
import React, { FunctionComponent, useContext, Fragment, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';

import FormActionButtons from 'components/FormActionButtons';
import RichTextEditor from 'components/RichTextEditor';
import { LANGS } from 'constants/enums';
import {
  ALPHANUMERIC_CHARS_REGEX,
  NOT_ONLY_NUMBERS_REGEX,
  NO_LEADING_TRAILING_SPACE_REGEX,
} from 'constants/regex';
import RootStoreContext from 'context/RootStoreContext';
import { PartnerAttributeWithValueId } from 'modules/Content24/Condition/api/partnerCode24api';
import {
  CODE24_MODEL_TYPES,
  EXIT_ATTRIBUTES,
  EXIT_ATTRIBUTES_KEYS,
} from 'modules/Content24/Condition/constants/code24types';
import { Exit } from 'modules/Content24/Condition/models/Code24Model';
import {
  getDataFromFinalFormValues,
  getInitialFormValues,
} from 'modules/Content24/Condition/utils/forms';
import { usePartnerAttributes } from 'modules/Content24/Condition/utils/hooks';
import {
  validateAtLeastOneExitAttributeSelected,
  validateStatementConditionWithDebounce,
  ExpressionError,
  validateStatementBuildTimeIfWithDebounce,
  validatePatientField,
  validatePatient4Regex,
  validateStatementTextConditionWithDebounce,
} from 'modules/Content24/Condition/utils/validationUtils';
import { TranslatedText } from 'types/types';

import DiagnosisCodes from '../../DiagnosisCodes';
import PatientRiskMessage from '../../PatientRiskMessage';
import ExitAttributeFormFields from '../ExitAttributesFormFields';
import ServiceRequestFormFields from '../ServiceRequestFormFields';

/**
 * @notExported
 */
interface ExitBlockFormProps {
  onCancel: () => void;
  onSubmit: (data: Exit) => void;
  activeLanguage: LANGS;
  data?: Exit;
  isDisabled?: boolean;
  diagnosisCodes?: {
    [key: string]: string[];
  };
}

export enum EXIT_TYPE {
  REGULAR = 'REGULAR',
  SERVICE_REQUEST = 'SERVICE_REQUEST',
}

export interface ExitFormData extends Omit<Exit, EXIT_ATTRIBUTES.APPOINTMENT_PRIORITY> {
  exitType: EXIT_TYPE[];
  [EXIT_ATTRIBUTES.APPOINTMENT_PRIORITY]: number | null | undefined;
}

function transformInitialData(lang: LANGS, data?: Exit) {
  const defaultValues: ExitFormData = {
    id: '',
    type: CODE24_MODEL_TYPES.EXIT,
    exitId: '',
    condition: '',
    patient: { [lang]: '' },
    recommendation: { [lang]: '' },
    buildTimeIf: '',
    textCondition: '',
    // Exit attributes are handled by Select controls and their empty value is "undefined",
    // differently than in case of Inputs ("") or InputNumbers (null)
    // Unfortunately that's not handled well by Formik - if the value is initially undefined,
    // then it's set and then cleared to be undefined again, Formik treats it
    // as an update (form becomes dirty). Therefore, empty string has to be used as default
    // for all cases, in which Select control uses mode=single and allowClear attributes.
    // This workaround, however, cannot be used for appointment priority, as the value is
    // number there. That's why null is used, which in turn requires ts-expect-error when
    // using the form value in ExitAttributeFormFields.
    [EXIT_ATTRIBUTES.LEVEL_OF_CARE]: '',
    [EXIT_ATTRIBUTES.URGENCY]: '',
    [EXIT_ATTRIBUTES.RESOURCE]: '',
    [EXIT_ATTRIBUTES.CAPABILITY]: [],
    [EXIT_ATTRIBUTES.TYPE_OF_CONSULTATION]: [],
    [EXIT_ATTRIBUTES.URL]: '',
    [EXIT_ATTRIBUTES.APPOINTMENT_PRIORITY]: null,
    exitType: [EXIT_TYPE.REGULAR],
  };
  const plainData = getInitialFormValues<Exit, ExitFormData>(defaultValues, data);

  const initialData = {
    ...plainData,
    exitType: [
      plainData.serviceRequests ? EXIT_TYPE.SERVICE_REQUEST : false,
      EXIT_ATTRIBUTES_KEYS.some(key => !!plainData[key]) || !plainData.serviceRequests
        ? EXIT_TYPE.REGULAR
        : false,
    ].filter(Boolean) as EXIT_TYPE[],
  };

  if (initialData.serviceRequests) {
    //@ts-expect-error API issue hotfix
    initialData.serviceRequests = initialData.serviceRequests.map(({ id }) => id);
  }

  // convert patient4 value to patient, if any, as we handle it as one field
  if (initialData.patient4) {
    initialData.patient = initialData.patient4;
  }

  return initialData;
}

const ExitBlockForm: FunctionComponent<ExitBlockFormProps> = ({
  data,
  onCancel,
  onSubmit,
  isDisabled,
  activeLanguage,
  diagnosisCodes,
}) => {
  const intl = useIntl();
  const { conditionStore, partnersStore, content24Store } = useContext(RootStoreContext);

  const initialValues: ExitFormData = useMemo(() => {
    return transformInitialData(activeLanguage, data);
  }, [data, activeLanguage]);

  const {
    partnerAttributesWithValues,
    partnerAttributesValues,
    handlePartnerAttributeUpdate,
    handlePartnerAttributeChange,
    isSavingPartnerAttributes,
    isAttributesContentDirty,
  } = usePartnerAttributes('exit', activeLanguage, initialValues.partner || []);

  const isConditionRequired = conditionStore.conditionStatementsAsOneList.some(
    item =>
      item.type === CODE24_MODEL_TYPES.EXIT &&
      item.id !== initialValues.id &&
      !('condition' in item)
  );
  const isServiceRequestEnabled = partnersStore.partnerCustomizations.get(
    'CODE24_SERVICE_REQUEST_ENABLED'
  );
  const exitTypes = (
    isServiceRequestEnabled ? [EXIT_TYPE.REGULAR, EXIT_TYPE.SERVICE_REQUEST] : [EXIT_TYPE.REGULAR]
  ).map(value => ({ label: `condition.exit-type-${value}`, value }));
  const requiredErrorMessage = intl.formatMessage({
    id: 'general.errors.required',
  });
  const wrongFormatErrorMessage = intl.formatMessage({
    id: 'general.errors.wrong-format',
  });
  const validationSchema = Yup.object()
    .shape({
      type: Yup.string().required(),
      exitId: Yup.string()
        .matches(
          ALPHANUMERIC_CHARS_REGEX,
          intl.formatMessage({
            id: 'general.errors.alphanumeric-characters-validation',
          })
        )
        .matches(
          NOT_ONLY_NUMBERS_REGEX,
          intl.formatMessage({
            id: 'general.errors.not_only_numbers_validation',
          })
        )
        .test(
          'isUsed',
          intl.formatMessage({ id: 'general.errors.already-in-use' }),
          value =>
            !conditionStore.conditionStatementsAsOneList.some(
              item =>
                item.id !== initialValues.id &&
                (item.id === value || ('exitId' in item && item.exitId === value))
            )
        )
        .required(requiredErrorMessage),
      condition: Yup.string()
        .test('isRequired', requiredErrorMessage, value => value || !isConditionRequired)
        .test(
          'isValidCondition',
          ({ translationKey, characters }: Partial<ExpressionError & Yup.TestMessageParams>) =>
            translationKey &&
            characters &&
            intl.formatMessage({ id: translationKey }, { characters }),
          validateStatementConditionWithDebounce
        ),
      patient: Yup.mixed().test(
        'isPatientValid',
        wrongFormatErrorMessage,
        function (this: Yup.TestContext, value: TranslatedText) {
          return (
            !value ||
            !value[activeLanguage] ||
            validatePatientField(value[activeLanguage]) ||
            this.createError({
              path: `patient.${activeLanguage}`,
              message: wrongFormatErrorMessage,
            })
          );
        }
      ),
      textCondition: Yup.string()
        .test(
          'isTextConditionRequired',
          requiredErrorMessage,
          function (this: Yup.TestContext, value: string) {
            const isRequired =
              !!this.parent.patient && validatePatient4Regex(this.parent.patient[activeLanguage]);
            return !isRequired || !!value;
          }
        )
        .test(
          'isValidTextCondition',
          ({ translationKey, characters }: Partial<ExpressionError & Yup.TestMessageParams>) =>
            translationKey &&
            characters &&
            intl.formatMessage({ id: translationKey }, { characters }),
          validateStatementTextConditionWithDebounce
        ),
      recommendation: Yup.object()
        .shape({
          [activeLanguage]: Yup.string().nullable(),
        })
        .nullable(),
      buildTimeIf: Yup.string()
        .test(
          'noLeadingTrailingSpace',
          intl.formatMessage({ id: 'general.errors.leading_trailing_space_not_allowed' }),
          function (this: Yup.TestContext, value: string) {
            return !NO_LEADING_TRAILING_SPACE_REGEX.test(value);
          }
        )
        .test(
          'isValidBuildTimeIf',
          ({ translationKey, characters }: Partial<ExpressionError & Yup.TestMessageParams>) =>
            translationKey &&
            characters &&
            intl.formatMessage({ id: translationKey }, { characters }),
          validateStatementBuildTimeIfWithDebounce
        )
        .nullable(),
    })
    .test(
      'atLeastOneExitAttributeRequired',
      intl.formatMessage({ id: 'general.errors.at-least-one-field-required' }),
      formFields =>
        (formFields.exitType.includes(EXIT_TYPE.SERVICE_REQUEST) &&
          formFields.exitType.length === 1) ||
        validateAtLeastOneExitAttributeSelected(formFields)
    );

  const handleSubmit = async (formData: ExitFormData) => {
    const { exitType } = formData;
    // remove exitType field value, as it's not a part of Exit model
    const dataToSubmit = getDataFromFinalFormValues<Exit>(formData, [
      'exitType',
      'partnerAttributesValues',
    ]);
    const isPatient4 =
      !!dataToSubmit.patient && Object.values(dataToSubmit.patient).some(validatePatient4Regex);
    const successfullyUpdatedAttributes = await handlePartnerAttributeUpdate();

    // if one language is in patient4, send all as patient4
    if (isPatient4) {
      dataToSubmit.patient4 = dataToSubmit.patient;
      delete dataToSubmit.patient;
    } else {
      if (dataToSubmit.patient4) delete dataToSubmit.patient4;
    }

    if (!exitType.includes(EXIT_TYPE.SERVICE_REQUEST)) {
      delete dataToSubmit.serviceRequests;
    }

    if (!exitType.includes(EXIT_TYPE.REGULAR)) {
      EXIT_ATTRIBUTES_KEYS.forEach(key => {
        delete dataToSubmit[key];
      });
    }

    if (exitType.includes(EXIT_TYPE.SERVICE_REQUEST) && !dataToSubmit.serviceRequests) {
      dataToSubmit.serviceRequests = [];
    }

    dataToSubmit.partner = partnerAttributesWithValues
      .map(({ attribute }) => {
        const isSuccessfullyUpdated = successfullyUpdatedAttributes.includes(attribute.value);
        const areAllTextValuesDeleted = !Object.values(
          partnerAttributesValues[attribute.value]
        ).some(stringValue => !!stringValue);

        // If all text values were deleted, remove the attribute from partner list
        if (isSuccessfullyUpdated) {
          return areAllTextValuesDeleted ? undefined : attribute;
        }

        // Values that haven't been successfully saved, should be sent with Exit data only if they currently have at least one language version
        // with non-empty value (to prevent saving new values without their code24 block counterpart).
        return partnerAttributesWithValues.find(
          ({ attribute: { id } }) =>
            id === attribute.id &&
            Object.values(partnerAttributesValues[attribute.value]).some(
              stringValue => !!stringValue
            )
        )?.attribute;
      })
      .filter(Boolean) as PartnerAttributeWithValueId[];

    onSubmit(dataToSubmit);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, dirty, submitForm, errors, setFieldValue }) => {
        const isTextConditionRequired =
          values.patient && validatePatient4Regex(values.patient[activeLanguage]);
        const isNewExit = !values.id;
        const isUrgencyChanged =
          !isNewExit && initialValues[EXIT_ATTRIBUTES.URGENCY] !== values[EXIT_ATTRIBUTES.URGENCY];
        const isConditionChanged = !isNewExit && initialValues.condition !== values.condition;
        const isSubmitConfirmed = isUrgencyChanged || isConditionChanged;
        // Cannot use Formik's isValid here, as it also tells if the form was touched. This works in most cases
        // but here we have to deal with Formik form content AND external data (partner attributes). So isValid
        // would remain false if only the external, non-Formik content has changed.
        const hasErrors = Object.keys(errors).length;
        const isDirty = dirty || isAttributesContentDirty;

        return (
          <Observer>
            {() => {
              const isFormDisabled = conditionStore.isLoading() || isDisabled;
              const isSaving = conditionStore.isLoading() || isSavingPartnerAttributes;

              return (
                <Form layout="vertical">
                  <Row gutter={16}>
                    <Col span={8}>
                      <Form.Item
                        name="exitId"
                        required
                        hasFeedback
                        label={<FormattedMessage id="condition-edit.exit-id-label" />}
                      >
                        <Input name="exitId" disabled={!!initialValues.exitId || isFormDisabled} />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        name="exitType"
                        label={<FormattedMessage id="condition-edit.exit-type-label" />}
                      >
                        <Select
                          name="exitType"
                          disabled={isFormDisabled}
                          mode="multiple"
                          options={exitTypes.map(({ value, label }) => ({
                            value,
                            label: intl.formatMessage({ id: label }),
                            disabled:
                              values.exitType.length === 1 && values.exitType.includes(value),
                          }))}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        name="condition"
                        label={<FormattedMessage id="condition-edit.condition-label" />}
                        required={isConditionRequired}
                        hasFeedback
                      >
                        <Input.TextArea
                          name="condition"
                          disabled={isFormDisabled}
                          rows={1}
                          autoSize
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        name={`patient.${activeLanguage}`}
                        label={<FormattedMessage id="condition-edit.patient-text-label" />}
                      >
                        <Input.TextArea
                          name={`patient.${activeLanguage}`}
                          disabled={isFormDisabled}
                          rows={1}
                          autoSize
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        name={`recommendation.${activeLanguage}`}
                        label={<FormattedMessage id="condition-edit.recommendation-label" />}
                      >
                        <Input.TextArea
                          name={`recommendation.${activeLanguage}`}
                          disabled={isFormDisabled}
                          rows={1}
                          autoSize
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        name="buildTimeIf"
                        label={<FormattedMessage id="condition-edit.build-time-if-label" />}
                      >
                        <Input name="buildTimeIf" disabled={isFormDisabled} />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        name="textCondition"
                        required={isTextConditionRequired}
                        hasFeedback
                        label={<FormattedMessage id="condition-edit.textCondition-label" />}
                      >
                        <Input name="textCondition" disabled={isFormDisabled} />
                      </Form.Item>
                    </Col>
                    {partnerAttributesWithValues.map(({ attribute }, index) => {
                      const isValueReady =
                        partnerAttributesValues[attribute.value] &&
                        partnerAttributesValues[attribute.value][activeLanguage] !== undefined;

                      if (!isValueReady) {
                        return null;
                      }

                      return (
                        <Col span={8} key={index}>
                          <AntdForm.Item
                            label={
                              attribute.displayPractitioner[intl.locale] ||
                              attribute.displayPractitioner[intl.defaultLocale]
                            }
                          >
                            <RichTextEditor
                              key={`${attribute.value}${activeLanguage}`}
                              isDisabled={isFormDisabled}
                              initialValue={
                                partnerAttributesValues[attribute.value][activeLanguage]
                              }
                              onChange={(data: string) => {
                                handlePartnerAttributeChange(attribute.value, data);
                              }}
                            />
                          </AntdForm.Item>
                        </Col>
                      );
                    })}
                    {values.exitType.includes(EXIT_TYPE.SERVICE_REQUEST) && (
                      <Col span={8}>
                        <ServiceRequestFormFields
                          isDisabled={isFormDisabled}
                          activeLanguage={activeLanguage}
                        />
                      </Col>
                    )}
                  </Row>

                  {content24Store.isDiagnosisCodesShown && diagnosisCodes && (
                    <>
                      <Divider />
                      <DiagnosisCodes diagnosisCodes={diagnosisCodes} />
                    </>
                  )}
                  {values.exitType.includes(EXIT_TYPE.REGULAR) && (
                    <Fragment>
                      <Divider />
                      <ExitAttributeFormFields
                        isDisabled={isFormDisabled}
                        values={values}
                        setFieldValue={setFieldValue}
                      />
                    </Fragment>
                  )}
                  <Divider />
                  <FormActionButtons
                    isSaving={isSaving}
                    isDisabled={isDisabled}
                    isValid={isDirty && !hasErrors}
                    onCancel={onCancel}
                    showCancelConfirm={isDirty}
                    cancelDeclineText={
                      <FormattedMessage id="condition-edit.statement-cancel-confirm" />
                    }
                    showSubmitConfirm={isSubmitConfirmed}
                    submitQuestionText={
                      <PatientRiskMessage
                        values={values}
                        initialValues={initialValues}
                        keys={[EXIT_ATTRIBUTES.URGENCY, 'condition']}
                      />
                    }
                    onSubmit={submitForm}
                  />
                </Form>
              );
            }}
          </Observer>
        );
      }}
    </Formik>
  );
};

export default ExitBlockForm;
