import { Modal, Divider } from 'antd';
import { Formik } from 'formik';
import { Form, Input } from 'formik-antd';
import React, { FunctionComponent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { RouteComponentProps, withRouter, useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import FormActionButtons from 'components/FormActionButtons';
import { LANGS } from 'constants/enums';
import { ALPHANUMERIC_CHARS_DASHES_HYPHENS_REGEX } from 'constants/regex';

/**
 * @notExported
 */
interface AddPartnerSelfcareAdviceProps extends RouteComponentProps {
  onSubmit: (id: string, lang: LANGS) => any;
  onCancel: () => void;
  isSaving: boolean;
  isVisible: boolean;
  usedSelfcareIds: string[];
}

/**
 * @notExported
 */
interface FormValues {
  id: string;
}

const AddPartnerSelfcareAdvice: FunctionComponent<AddPartnerSelfcareAdviceProps> = ({
  onSubmit,
  onCancel,
  isSaving,
  isVisible,
  usedSelfcareIds,
}) => {
  const intl = useIntl();
  const history = useHistory();

  const validationSchema = Yup.object().shape({
    id: Yup.string()
      .test(
        'isUsed',
        intl.formatMessage({ id: 'general.errors.already-in-use' }),
        value => value && !usedSelfcareIds.includes(value.toLowerCase())
      )
      .required(
        intl.formatMessage({
          id: 'general.errors.required',
        })
      )
      .matches(
        ALPHANUMERIC_CHARS_DASHES_HYPHENS_REGEX,
        intl.formatMessage({
          id: 'general.errors.alphanumeric-characters-dashes-hyphens-validation',
        })
      ),
  });

  const formLayouts = {
    form: {
      labelCol: {
        sm: { span: 14 },
      },
      wrapperCol: {
        sm: { span: 10 },
      },
    },
    sideBySide: {
      labelCol: {
        span: 12,
      },
      wrapperCol: {
        span: 12,
      },
    },
  };

  const handleSubmit = async (data: FormValues) => {
    await onSubmit(data.id, intl.locale as LANGS);
    history.replace(`/content24/selfcare/${data.id}`);
  };
  const initialValues = {
    id: '',
  };

  return (
    <Modal
      open={isVisible}
      destroyOnClose
      title={<FormattedMessage id="selfcare.new-selfcare-information" />}
      footer={null}
      closable={false}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnChange
        render={({ isValid, dirty, setFieldTouched }) => (
          <Form layout="horizontal" labelAlign="left" {...formLayouts.form}>
            <Form.Item
              name="id"
              required
              hasFeedback
              label={<FormattedMessage id="general.title" />}
              {...formLayouts.sideBySide}
            >
              <Input name="id" disabled={isSaving} onChange={() => setFieldTouched('id')} />
              {/* TODO replace with initalTouched after migrating to Formik 2.0 */}
            </Form.Item>
            <Divider />
            <FormActionButtons
              isSaving={isSaving}
              isValid={isValid && dirty}
              onCancel={onCancel}
              showCancelConfirm={dirty}
            />
          </Form>
        )}
      />
    </Modal>
  );
};

export default withRouter(AddPartnerSelfcareAdvice);
