import React, { useContext, useEffect, useState } from 'react';

import RootStoreContext from 'context/RootStoreContext';

import styles from './Image.module.css';

interface ImageProps {
  src: string;
  alt: string;
}

export const Image = ({ src, alt }: ImageProps) => {
  const {
    partnersStore: { imageStore },
  } = useContext(RootStoreContext);
  const [imageSrc, setImageSrc] = useState('');

  useEffect(() => {
    let isCancelled = false;
    const fetcher = async (imageId: string) => {
      const fetchedImg = await imageStore.getImage(imageId);
      if (!isCancelled) {
        const imgSrc = URL.createObjectURL(fetchedImg);
        setImageSrc(imgSrc);
      }
    };

    if (src) {
      void fetcher(src);
    }

    return () => {
      isCancelled = true;
    };
    // Should not react to imageStore changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src]);

  useEffect(
    () => () => {
      if (imageSrc) {
        URL.revokeObjectURL(imageSrc);
      }
    },
    [imageSrc]
  );

  return <img className={styles.image} src={imageSrc} alt={alt} />;
};
