import { Spin, Typography } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { styled } from 'styled-components';

const Styled = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
};

export const EnduringProgressLoader = ({
  className,
  label,
}: {
  className?: string;
  label?: React.ReactNode;
}) => (
  <Styled.Container className={className}>
    <Spin percent={'auto'} size="large" />

    <Typography.Text strong>
      {label || (
        <>
          <FormattedMessage id={'general.loading'} />
          ...
        </>
      )}
    </Typography.Text>
  </Styled.Container>
);
