import axios from 'axios';
import { stringify } from 'query-string';

import { CHANGE_PARTNER_URL, LOGIN_ENDPOINTS, LOGIN_METHODS } from 'constants/login';
import { IDENTITIES } from 'constants/practitioner';

export interface Token {
  token: string;
}

export const authenticateWithSITHS = () => axios.post<Token>(LOGIN_ENDPOINTS.SITHS, {});

export const authenticateWithHsaIdDev = (hsaId: string) =>
  axios.post<Token>(
    LOGIN_ENDPOINTS.HSAID_DEV + `?${stringify({ id: hsaId, idType: IDENTITIES.HSA_ID })}`
  );

export const changePartner = (partnerId: string) =>
  axios.patch<Token>(CHANGE_PARTNER_URL, { partnerId });

export const authenticateWithNorwegianBankIdDev = (norwegianBankId: string) =>
  axios.post<Token>(
    `${LOGIN_ENDPOINTS.NORWEGIAN_BANK_ID_DEV}?${stringify({
      id: norwegianBankId,
      idType: IDENTITIES.NORWEGIAN_PERSONAL_IDENTITY_NUMBER,
    })}`
  );

export const authenticateWithNorwegianBankId = (code: string, state: string) =>
  axios.get<Token>(LOGIN_ENDPOINTS.NORWEGIAN_BANK_ID_COLLECT, { params: { code, state } });

export const getLoginMethods = () =>
  axios.get<{ type: LOGIN_METHODS }[]>('/rest/practitioner-auth/login-methods/v1', {
    // https://platform24.atlassian.net/browse/AX-17712
    // This should fail silently if login methods endpoint is not ready.
    ignoreErrorStatuses: [400, 500, 502, 401, 403, 404],
  });

export const collectSSOToken = () => axios.get<Token>('/rest/practitioner-auth/collect');
