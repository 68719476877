import { DeleteOutlined, PicLeftOutlined } from '@ant-design/icons';
import { routes } from '@platform24/admin-ui-utils';
import { Button, Card, Popconfirm, Popover, Skeleton, Typography } from 'antd';
import { useAtomValue } from 'jotai';
import React, { Fragment, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { styled } from 'styled-components';

import withOnUnloadGuard from 'components/HOC/withOnUnloadGuard';
import PageHeader from 'components/PageHeader';
import { LOCAL_STORAGE_KEYS } from 'constants/localStorageKeys';
import { getLanguage } from 'context/lang.utils';
import { useBoolean } from 'hooks/useBoolean';
import { content24Atoms } from 'state/content24';
import { partnersAtoms } from 'state/partners';

import { EditArticleCard } from './EditArticleCard';
import { PreviewArticleCard } from './PreviewArticleCard/PreviewArticleCard';
import { useFullArticle } from '../../api/articlesApi.hooks';

const Styled = {
  Container: styled.div`
    max-width: 820px;
  `,
  DeleteButton: styled(Button)`
    margin-bottom: 8px;
  `,
};

const ArticlePage = () => {
  const { id } = useParams<{ id: string; type: 'article' | 'selfcare' }>();

  const partnerId = localStorage.getItem(LOCAL_STORAGE_KEYS.PARTNER_ID);
  const languages = useAtomValue(partnersAtoms.availableLanguages);
  const canEditSelfcareAdvice = useAtomValue(content24Atoms.canEditSelfcareAdvice);

  const fullArticleQuery = useFullArticle({
    partnerId,
    articleId: id,
    languages,
  });
  const isLoading = fullArticleQuery.isLoading && fullArticleQuery.fetchStatus !== 'idle';
  const article = fullArticleQuery.data;

  const isEditing = useBoolean(false);

  const history = useHistory();
  useEffect(
    function redirectToListIfNotFound() {
      const isTryingToFetchArticle = fullArticleQuery.isLoading || fullArticleQuery.isFetching;

      if (!isTryingToFetchArticle && !fullArticleQuery.data) {
        history.replace(routes.content24.articles.list.buildPath({}));
      }
    },
    [history, fullArticleQuery.isLoading, fullArticleQuery.isFetching, fullArticleQuery.data]
  );

  const title = useMemo(
    () =>
      fullArticleQuery.isLoading ? (
        <Skeleton.Input size="small" />
      ) : (
        <>{article?.metadata.title[getLanguage()] || id}</>
      ),
    [article, id, fullArticleQuery.isLoading]
  );

  const breadcrumbs = useMemo(
    () => [
      {
        icon: <PicLeftOutlined />,
        text: <FormattedMessage id="main-navigation.content24" />,
      },
      {
        text: <FormattedMessage id="articles.title" />,
        link: routes.content24.articles.list.buildPath({}),
      },
      {
        text: title,
      },
    ],
    [title]
  );

  const headerActions = (
    <Popconfirm
      title={<FormattedMessage id="general.sure-to-delete" />}
      cancelText={<FormattedMessage id="general.cancel" />}
      onConfirm={() => {
        /* noop */
      }}
    >
      <Popover
        open={canEditSelfcareAdvice ? false : undefined}
        content={
          <div style={{ maxWidth: 240 }}>
            <FormattedMessage id="general.no-access" />
          </div>
        }
      >
        <Styled.DeleteButton
          icon={<DeleteOutlined style={{ marginRight: 4 }} />}
          disabled={isLoading || !canEditSelfcareAdvice}
        >
          <>
            <FormattedMessage id="articles.view.delete" />
          </>
        </Styled.DeleteButton>
      </Popover>
    </Popconfirm>
  );

  return (
    <Fragment>
      <Styled.Container>
        <PageHeader
          content={<Typography.Title level={2}>{title}</Typography.Title>}
          breadcrumbs={breadcrumbs}
          headerActions={headerActions}
        />

        {article ? (
          isEditing.value ? (
            <EditArticleCard
              currentArticle={article}
              isLoading={isLoading}
              onEditCancel={isEditing.setFalse}
            />
          ) : (
            <PreviewArticleCard
              article={article}
              isLoading={isLoading}
              onEdit={isEditing.setTrue}
            />
          )
        ) : (
          <Card title={<Skeleton.Input size="small" />}>
            <Skeleton />
          </Card>
        )}
      </Styled.Container>
    </Fragment>
  );
};

export default withOnUnloadGuard(ArticlePage);
