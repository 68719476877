import {
  RotateRightOutlined,
  SaveOutlined,
  UploadOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import { Button, Modal, Slider } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import Cropper, { type Area } from 'react-easy-crop';
import { FormattedMessage } from 'react-intl';
import { styled } from 'styled-components';
import { useFilePicker } from 'use-file-picker';
import {
  FileAmountLimitValidator,
  FileSizeValidator,
  FileTypeValidator,
} from 'use-file-picker/validators';

import { defaultFlashMessageService } from 'config/appConfig';
import { useBoolean } from 'hooks/useBoolean';

import { getCroppedImg } from './cropImage';

const Styled = {
  SliderContainer: styled.div`
    position: relative;
    padding: 0 30px;

    .anticon {
      position: absolute;
      top: -2px;
      width: 16px;
      height: 16px;
      color: rgba(0, 0, 0, 0.45);
      font-size: 16px;
      line-height: 1;
    }

    .anticon:first-child {
      left: 0;
    }

    .anticon:last-child {
      right: 0;
    }

    .ant-slider-handle {
      width: 14px;
      height: 14px;
    }
  `,
  CropperContainer: styled.div`
    position: relative;
    height: 446px;
    width: 752px;
  `,
  ButtonsContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin-top: 8px;
  `,
};

export const ImageUploadButton = ({
  label,
  disabled,
  targetImageSize,
  onUpload,
}: {
  label: React.ReactNode;
  disabled: boolean;
  targetImageSize?: { width: number; height: number };
  onUpload: (blobPath: string | null) => void;
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotationInDegrees, setRotationInDegrees] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
  const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const imageModalOpen = useBoolean(false);
  const [sourceImageFile, setSourceImageFile] = useState<string | null>(null);
  const handleSave = useCallback(async () => {
    if (!sourceImageFile || !croppedAreaPixels) {
      return;
    }

    try {
      const croppedImage = await getCroppedImg({
        imageSrc: sourceImageFile,
        pixelCrop: croppedAreaPixels,
        rotation: rotationInDegrees,
        targetImageSize,
      });
      if (!croppedImage) {
        return;
      }

      onUpload(croppedImage);
      imageModalOpen.setFalse();
    } catch (e) {
      console.error(e);
    }
  }, [
    sourceImageFile,
    croppedAreaPixels,
    rotationInDegrees,
    targetImageSize,
    onUpload,
    imageModalOpen,
  ]);

  const handleScalePercentChange = useCallback(
    (newScalePercent: number | number[]) => {
      if (Array.isArray(newScalePercent) || isNaN(newScalePercent)) {
        // Note that this should not happen.
        // It's just a safeguard copied from: https://ant.design/components/slider
        return;
      }
      setZoom(newScalePercent / 100);
    },
    [setZoom]
  );

  const handleRotate = useCallback(() => {
    setRotationInDegrees((rotationInDegrees + 90) % 360);
  }, [setRotationInDegrees, rotationInDegrees]);

  const scaleFormatter = useCallback((value?: number | undefined) => `${value ?? 100}%`, []);

  const { openFilePicker, plainFiles } = useFilePicker({
    accept: 'image/jpeg, image/png, image/jpg, image/JPEG, image/PNG, image/JPG',
    multiple: false,
    validators: [
      new FileAmountLimitValidator({ max: 1 }),
      new FileTypeValidator(['jpg', 'png', 'jpeg', 'JPG', 'PNG', 'JPEG']),
      new FileSizeValidator({ maxFileSize: 4 * 1024 * 1024 /* 4 MB */ }),
    ],
  });

  useEffect(() => {
    if (plainFiles.length === 0) {
      imageModalOpen.setFalse();
      return;
    }

    const file = plainFiles[0];

    if (!file.type.startsWith('image/')) {
      // The accept 'image/*' in the file picker can not be entirely trusted.
      // Apparently it can be bypassed on Windows in some cases.
      // Not important from a security aspect but handles errors much cleaner.
      defaultFlashMessageService.translatedError('images.error.unsupported-format');
      return;
    }

    setSourceImageFile(URL.createObjectURL(file));
    setZoom(1);
    setRotationInDegrees(0);
    imageModalOpen.setTrue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plainFiles, setSourceImageFile, imageModalOpen.setFalse, imageModalOpen.setTrue]);

  const showCropper = useBoolean(false);
  const handleModalAnimationEnd = useCallback(
    (open: boolean) => {
      showCropper.setValue(open);
    },
    [showCropper]
  );

  return (
    <div>
      <Styled.ButtonsContainer>
        <Button icon={<UploadOutlined />} disabled={disabled} onClick={openFilePicker}>
          <>{label}</>
        </Button>
      </Styled.ButtonsContainer>

      <Modal
        afterOpenChange={handleModalAnimationEnd}
        footer={[
          <Button key="cancel" onClick={imageModalOpen.setFalse}>
            <>
              <FormattedMessage id="general.cancel" />
            </>
          </Button>,
          <Button key="rotate" icon={<RotateRightOutlined />} onClick={handleRotate}>
            <>
              <FormattedMessage id="general.rotate" />
            </>
          </Button>,
          <Button
            key="save"
            disabled={disabled}
            icon={<SaveOutlined />}
            type="primary"
            onClick={handleSave}
          >
            <>
              <FormattedMessage id="general.save" />
            </>
          </Button>,
        ]}
        maskClosable={false}
        open={imageModalOpen.value}
        title="Edit"
        width={800}
        onCancel={imageModalOpen.setFalse}
      >
        <Styled.CropperContainer>
          {showCropper.value ? (
            <Cropper
              aspect={
                targetImageSize ? targetImageSize?.width / targetImageSize?.height : undefined
              }
              crop={crop}
              image={sourceImageFile || ''}
              rotation={rotationInDegrees}
              showGrid={false}
              zoom={zoom}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onRotationChange={setRotationInDegrees}
              onZoomChange={setZoom}
            />
          ) : null}
        </Styled.CropperContainer>

        <Styled.SliderContainer>
          <ZoomOutOutlined />
          <Slider
            max={300}
            min={40}
            tooltip={{
              formatter: scaleFormatter,
            }}
            value={zoom * 100}
            onChange={handleScalePercentChange}
          />
          <ZoomInOutlined />
        </Styled.SliderContainer>
      </Modal>
    </div>
  );
};
