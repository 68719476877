import { Button, Input, Form } from 'antd';
import { Store } from 'antd/lib/form/interface';
import React, { useContext, useState, useCallback } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import RootStoreContext from 'context/RootStoreContext';

interface Props extends WrappedComponentProps, RouteComponentProps {}

const NorwegianDevLogin = ({ intl, history }: Props) => {
  const { authStore } = useContext(RootStoreContext);

  const [isLoading, handleLoadingStateChange] = useState(false);

  const handleSubmit = useCallback(
    async (values: Store) => {
      try {
        handleLoadingStateChange(true);
        await authStore.loginWithDevNorwegianBankId(values.norwegianBankId);
        /* eslint-disable no-empty */
      } catch (error: any) {
      } finally {
        history.push('/login/select-partner');
      }
    },
    [authStore, history]
  );

  return (
    <Form onFinish={handleSubmit} layout="vertical">
      <Form.Item
        label={<FormattedMessage id="general.norwegian-personal-id" />}
        name="norwegianBankId"
      >
        <Input
          size="large"
          placeholder={intl.formatMessage({
            id: 'general.norwegian-bank-id-pattern',
          })}
          data-testid="norwegian-bank-id-input"
          disabled={isLoading}
        />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          disabled={isLoading}
          loading={isLoading}
          htmlType="submit"
          size="large"
          block
          data-testid="norwegian-bank-id-login-btn"
        >
          <FormattedMessage id="login.login" />
        </Button>
      </Form.Item>
    </Form>
  );
};

export default injectIntl(withRouter(NorwegianDevLogin));
