import { jotaiStore as store } from 'config/jotaiStore';
import { LOCAL_STORAGE_KEYS } from 'constants/localStorageKeys';
import { RESOURCE_TYPES } from 'constants/permissions';
import { userPermissionsAtoms, userPermissionsSelectors } from 'state/userPermissions';

const getCanEditContent24Resource = (
  resourceType: RESOURCE_TYPES.CODE24 | RESOURCE_TYPES.HOOKS | RESOURCE_TYPES.SELFCARE
) => {
  const partnerId = localStorage.getItem(LOCAL_STORAGE_KEYS.PARTNER_ID);
  if (!partnerId) {
    return false;
  }

  return partnerId
    ? userPermissionsSelectors.getPermission(
        {
          // Currently there's no way to set partner scope permission in Manage24
          // Details: https://platform24.atlassian.net/browse/AX-30158
          originId: partnerId,
          resourceType,
        },
        store.get(userPermissionsAtoms.canEditCurrentPartner)
      )
    : false;
};

export const content24Selectors = {
  getCanEditContent24Resource,
};
