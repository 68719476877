import { observable, action, runInAction, computed } from 'mobx';

import { fetchCareUnit, updateCareUnit, createCareUnit, CareUnit } from 'api/careUnitsApi';

import StateManager from './abstractStores/StateManager';
import RootStore from './RootStore';

export default class CareUnitStore extends StateManager {
  /**
   * we must always have partner id of current care unit
   * but at the moment CareUnit object does not contain partner id inside
   * so we need to store it separately
   *
   * We don't want to allow setting them independently since it can lead to inconsistency
   */
  @observable
  compoundCareUnit?: [careUnit: CareUnit, partnerId: string];

  @computed
  get careUnit(): CareUnit | undefined {
    // careUnit?: CareUnit;
    return this.compoundCareUnit?.[0];
  }

  @computed
  get canBeEditedByMe(): boolean {
    if (!this.compoundCareUnit) {
      return false;
    }

    const [careUnit, careProviderId] = this.compoundCareUnit;
    return this.rootStore.userPermissionsStore.canViewCareUnit(careProviderId, careUnit.id);
  }

  constructor(private rootStore: RootStore) {
    super();
  }

  fetchCareUnit = async (careProviderId: string, id: string) => {
    const { partnersStore } = this.rootStore;
    this.setLoading();

    try {
      const { data } = await fetchCareUnit(partnersStore.partnerId, careProviderId, id);

      runInAction(() => {
        this.compoundCareUnit = [data, careProviderId];
      });

      this.setLoaded();
    } catch (e) {
      this.manageException(e);
    }
  };

  updateCareUnit = async (careProviderId: string, careUnit: CareUnit) => {
    const { userDataStore: userStore, partnersStore } = this.rootStore;

    this.setSaving();

    try {
      const { data: updatedCareUnit } = await updateCareUnit(
        partnersStore.partnerId,
        careProviderId,
        careUnit
      );

      await userStore.fetchUserCareUnits({ force: true });

      runInAction(() => {
        this.compoundCareUnit = [updatedCareUnit, careProviderId];
      });

      this.setLoaded();
    } catch (e) {
      this.manageException(e);
    }
  };

  createCareUnit = async (careProviderId: string, careUnit: CareUnit) => {
    const { partnersStore } = this.rootStore;

    this.setSaving();

    try {
      const { data: newCareUnit } = await createCareUnit(
        partnersStore.partnerId,
        careProviderId,
        careUnit
      );

      runInAction(() => {
        this.compoundCareUnit = [newCareUnit, careProviderId];
      });

      this.setLoaded();
    } catch (e) {
      this.manageException(e);
    }
  };

  @action
  dispose() {
    this.compoundCareUnit = undefined;
  }

  manageException = (error: unknown) => {
    this.setError();
    throw error;
  };
}
