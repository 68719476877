import { Form } from 'antd';
import type { FormItemProps, RuleObject } from 'antd/es/form';
import React from 'react';
import { styled } from 'styled-components';

import { PopoverTooltip } from './PopoverTooltip';

const Styled = {
  FormItem: styled(Form.Item)`
    label {
      width: 100%;
    }
  `,
  LabelContainer: styled.div`
    display: flex;
    align-items: center;
    width: 100%;
  `,
  LabelText: styled.span`
    margin-right: 4px;
  `,
};

export interface LabelledFieldProps extends FormItemProps {
  label: string;
  name?: string | number | Array<string | number>;
  afterLabel?: React.ReactNode;
  className?: string;
  tooltip?: React.ReactNode;
  required?: boolean;
  rules?: RuleObject[];
  children: React.ReactNode;
}
export const LabelledField = ({
  label,
  name,
  afterLabel,
  className,
  tooltip,
  required,
  rules,
  children,
  ...formItemProps
}: LabelledFieldProps) => (
  <Styled.FormItem
    className={className}
    label={
      <Styled.LabelContainer>
        <Styled.LabelText>{label}</Styled.LabelText>
        {tooltip && <PopoverTooltip>{tooltip}</PopoverTooltip>}
        {afterLabel}
      </Styled.LabelContainer>
    }
    messageVariables={{ label: label.toLocaleLowerCase() }}
    name={name}
    required={required}
    rules={rules ?? [{ required }]}
    {...formItemProps}
  >
    {children}
  </Styled.FormItem>
);
