import { EditOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Card, Popover, Spin, Typography } from 'antd';
import { useAtomValue } from 'jotai';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { styled } from 'styled-components';

import { content24Atoms } from 'state/content24';
import { partnersAtoms } from 'state/partners';

import { PreviewMetadataCard } from './PreviewMetadataCard';
import { RenderPreview } from './RenderPreview';
import { Article } from '../../../types/article';
import { CardTitle } from '../CardTitle';
import { LanguageTabs } from '../LanguageTabs';
import { withLanguageTabState } from '../LanguageTabs/LanguageTabs.state';

const Styled = {
  CardTitle: styled(Typography.Title)`
    font-weight: normal !important;
    margin-bottom: 16px !important;
  `,
  LanguageTabs: styled(LanguageTabs)`
    margin-top: -8px;
  `,
  ContentCard: styled(Card)`
    margin-top: 16px;
  `,
};

interface PreviewArticleCardProps {
  article: Article;
  isLoading: boolean;
  onEdit: () => void;
}
const PreviewArticleCardBase = ({ article, isLoading, onEdit }: PreviewArticleCardProps) => {
  const availableLanguages = useAtomValue(partnersAtoms.availableLanguages);
  const defaultLanguage = useAtomValue(partnersAtoms.defaultLanguage);

  const canEditSelfcareAdvice = useAtomValue(content24Atoms.canEditSelfcareAdvice);

  return (
    <Card
      title={<Typography.Text>View article</Typography.Text>}
      extra={
        <Popover
          open={canEditSelfcareAdvice ? false : undefined}
          content={
            <div style={{ maxWidth: 240 }}>
              <FormattedMessage id="general.no-access" />
            </div>
          }
        >
          <Button
            onClick={onEdit}
            disabled={isLoading || !canEditSelfcareAdvice}
            icon={<EditOutlined />}
          >
            <FormattedMessage id="general.edit" />
          </Button>
        </Popover>
      }
    >
      <Styled.LanguageTabs defaultLanguage={defaultLanguage} languages={availableLanguages}>
        {({ selectedLanguage }) =>
          isLoading ? (
            <Spin size="large" indicator={<LoadingOutlined spin />} />
          ) : (
            <>
              <PreviewMetadataCard article={article} selectedLanguage={selectedLanguage} />

              <Styled.ContentCard size="small">
                <CardTitle tooltip={<FormattedMessage id="articles.content.tooltip" />}>
                  <FormattedMessage id="articles.content.title" />
                </CardTitle>

                <RenderPreview text={article.text[selectedLanguage]} />
              </Styled.ContentCard>
            </>
          )
        }
      </Styled.LanguageTabs>
    </Card>
  );
};

export const PreviewArticleCard = withLanguageTabState(PreviewArticleCardBase);
