import { LoadingOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { COMMIT_STATUS } from 'modules/PartnerStatus/api/partnerStatusApi';

import styles from './CommitStatusTag.module.css';

export const COMMIT_STATUS_TAG_MAP: Record<string, { color: string; translationId: string }> = {
  [COMMIT_STATUS.SUCCESS]: {
    color: 'green',
    translationId: 'partner-status.commit.success',
  },
  [COMMIT_STATUS.FAILED]: {
    color: 'red',
    translationId: 'partner-status.commit.failed',
  },
  [COMMIT_STATUS.CANCELED]: {
    color: 'gold',
    translationId: 'partner-status.commit.canceled',
  },
  [COMMIT_STATUS.CREATED]: {
    color: 'blue',
    translationId: 'partner-status.commit.created',
  },
  [COMMIT_STATUS.SKIPPED]: {
    color: 'gray',
    translationId: 'partner-status.commit.skipped',
  },
};

interface Props {
  status: COMMIT_STATUS;
}

const CommitStatusTag: FunctionComponent<Props> = ({ status }) => {
  switch (status) {
    case COMMIT_STATUS.FAILED:
    case COMMIT_STATUS.SUCCESS:
    case COMMIT_STATUS.CANCELED:
    case COMMIT_STATUS.CREATED:
    case COMMIT_STATUS.SKIPPED:
      return (
        <Tag color={COMMIT_STATUS_TAG_MAP[status].color} className={styles.tag}>
          <FormattedMessage id={COMMIT_STATUS_TAG_MAP[status].translationId} />
        </Tag>
      );
    default:
      return (
        <Tag color="blue" icon={<LoadingOutlined />} className={styles.tag}>
          <FormattedMessage id="partner-status.commit.running" />
        </Tag>
      );
  }
};

export default CommitStatusTag;
