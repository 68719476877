import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Table, Button, Popconfirm } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { IObservableArray, action, observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import { EDIT_ACTIONS_FIELD } from 'constants/general';
import { InputOption, UserRole } from 'types/types';

import UnitRoleForm from '../UnitRoleForm';

interface Props {
  isSaving?: boolean;
  practitionersWithRoles: IObservableArray<UserRole>;
  allPractitioners?: InputOption[];
  roles?: InputOption[];
  isEditingAvailable: boolean;
  onDelete?: (practitionerId: string) => any;
  onEdit?: (practitionerWithRole: UserRole) => any;
}

@observer
class UnitRolesTable extends Component<Props> {
  @observable editedRole?: UserRole;

  get columns() {
    const columns: ColumnProps<UserRole>[] = [
      {
        title: <FormattedMessage id="add-roles-form.header.practitioner" />,
        dataIndex: 'practitionerName',
      },
      {
        title: <FormattedMessage id="add-roles-form.header.role" />,
        dataIndex: 'role',
      },
    ];

    if (this.props.isEditingAvailable) {
      columns.push({
        title: <FormattedMessage id="general.actions" />,
        dataIndex: EDIT_ACTIONS_FIELD,
        render: (_: string, record: UserRole) => (
          <div>
            <Button type="link" icon={<EditOutlined />} onClick={() => this.handleEdit(record)} />
            <Popconfirm
              title={<FormattedMessage id="general.sure-to-delete" />}
              cancelText={<FormattedMessage id="general.cancel" />}
              onConfirm={() => this.props.onDelete && this.props.onDelete(record.practitionerId)}
            >
              <Button type="link" icon={<DeleteOutlined />} />
            </Popconfirm>
          </div>
        ),
      });
    }

    return columns;
  }

  @action
  handleEdit = (role: UserRole) => {
    this.editedRole = role;
  };

  @action
  handleCancel = () => {
    this.editedRole = undefined;
  };

  @action
  handleSubmit = (role: UserRole) => {
    this.editedRole = undefined;
    this.props.onEdit && this.props.onEdit(role);
  };

  render() {
    const { practitionersWithRoles, allPractitioners, isSaving, roles } = this.props;

    return (
      <Fragment>
        <Table<UserRole>
          dataSource={practitionersWithRoles.slice()}
          pagination={false}
          loading={isSaving}
          columns={this.columns}
          rowKey="practitionerId"
        />
        <UnitRoleForm
          data={this.editedRole}
          roles={roles || []}
          practitioners={allPractitioners || []}
          isSaving={!!isSaving}
          isVisible={!!this.editedRole}
          onSubmit={this.handleSubmit}
          onCancel={this.handleCancel}
        />
      </Fragment>
    );
  }
}

export default UnitRolesTable;
