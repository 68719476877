import { observable, IObservableArray, runInAction } from 'mobx';

import { fetchRoles, fetchResourceTypes, fetchAdministrativeRoles } from 'api/rolesApi';
import { DEPRECATED_ROLES, ROLES } from 'constants/roles';

import StateManager from './abstractStores/StateManager';

export default class RolesStore extends StateManager {
  @observable resourceTypes: IObservableArray<ROLES> = observable.array([]);
  @observable administrativeRoles: IObservableArray<ROLES> = observable.array([]);
  @observable availableRoles: IObservableArray<ROLES> = observable.array([]);

  fetchAvailableRoles = async () => {
    if (this.availableRoles.length > 0) {
      return;
    }

    this.setLoading();

    try {
      const { data } = await fetchRoles();

      runInAction(() => {
        // TODO: remove filtering when Super Admin will be excluded from the BE response - AX-6410
        this.availableRoles.replace(data.filter(role => role !== ROLES.SUPER_ADMIN));
      });

      this.setLoaded();
    } catch (e) {
      this.manageException(e);
    }
  };

  fetchResourceTypes = async () => {
    if (this.resourceTypes.length > 0) {
      return;
    }

    this.setLoading();

    try {
      const { data } = await fetchResourceTypes();

      runInAction(() => {
        this.resourceTypes.replace(data);
      });

      this.setLoaded();
    } catch (e) {
      this.manageException(e);
    }
  };

  fetchAdministrativeRoles = async () => {
    if (this.administrativeRoles.length > 0) {
      return;
    }

    this.setLoading();

    try {
      const { data } = await fetchAdministrativeRoles();

      runInAction(() => {
        // This is only temporary fix, this should be resolved on the BE side
        // See https://platform24.atlassian.net/browse/AX-11756 for details
        this.administrativeRoles.replace(data.filter(role => !DEPRECATED_ROLES.includes(role)));
      });

      this.setLoaded();
    } catch (e) {
      this.manageException(e);
    }
  };

  manageException = (error: unknown) => {
    this.setError();
    throw error;
  };
}
